import { OrgTreeFilterModel } from 'src/app/features/search/models/org-tree-filter.model';
import { RadioGroupOptions } from 'src/app/shared/models/form.model';

export interface ReportFilters {
  age: boolean;
  date: boolean;
  deIdentification: boolean;
  qaOnly: boolean;
  gender: boolean;
  geography: boolean;
  status: boolean;
  includePhotos: boolean;
  latestCases: boolean;
  organizationsTree: boolean;
  periodType: boolean;
  periodPresets: boolean;
  photoTypes: boolean;
  qaStages: boolean;
  qualityAssurance: boolean;
  recordDate: boolean;
  rejectedRecords: boolean;
  referringOrganization: boolean;
  reportedComplications: boolean;
  repairTypes: boolean;
  surgeons: boolean;
  practitioners: boolean;
  orthoCasesTypes: boolean;
  groupByScope: boolean;
  surgicalIntervention: boolean;
  treatmentNotCompliant: boolean;
  orthodontists: boolean;
  reviewers: boolean;
  speechAssessmentStates: boolean;
  speechTreatmentStates: boolean;
  speechAssessmentRecommendations: boolean;
  speechAssessmentStatuses: boolean;
  speechTreatmentStatuses: boolean;
  orthoTypes: boolean;
  orthoStatuses: boolean;
  orthoRecordDates: boolean;
  mohGrades: boolean;
  treatmentStatus: boolean;
  exclusions: boolean;
  statsType: boolean;
  nutritionists: boolean;
  ageGroups: boolean;
  nutritionTypes: boolean;
  nutritionStatuses: boolean;
  orthoQaGrading: boolean;
  ageGroupsWithTreatmentStatuses: boolean;
}

export enum ReportDownloadTypes {
  EXCEL = 'excel',
  EXCEL_ADMIN = 'excelAdmin',
  EXCEL_ADMIN_ID = 'excelAdminId',
  EXCEL_WITH_AGE_GROUPS = 'excelAgeGroups',
  EXCEL_ADMIN_WITH_AGE_GROUPS = 'excelAdminAgeGroups',
  EXCEL_ADMIN_ID_WITH_AGE_GROUPS = 'excelAdminIdAgeGroups'
}
export interface ReportDownloadOptions {
  [ReportDownloadTypes.EXCEL]: boolean;
  [ReportDownloadTypes.EXCEL_ADMIN]: boolean;
  [ReportDownloadTypes.EXCEL_ADMIN_ID]: boolean;
  [ReportDownloadTypes.EXCEL_WITH_AGE_GROUPS]: boolean;
  [ReportDownloadTypes.EXCEL_ADMIN_WITH_AGE_GROUPS]: boolean;
  [ReportDownloadTypes.EXCEL_ADMIN_ID_WITH_AGE_GROUPS]: boolean;
}

export const AllReportTypes = {
  MONTH_END: 'MONTH_END',
  ORTHOGNATHIC_STATS: 'ORTHOGNATHIC_STATS',
  ORTHOGNATHIC_TREATMENTS: 'ORTHOGNATHIC_TREATMENTS',
  ORTHODONTIC_TREATMENTS: 'ORTHODONTIC_TREATMENTS',
  ORTHODONTIC_TREATMENTS_COMPACT: 'ORTHODONTIC_TREATMENTS_COMPACT',
  PHOTO_REPORT: 'PHOTO_REPORT',
  QA_ORTHO_CASES: 'QA_ORTHO_CASES',
  QA_REVIEWER_RELIABILITY: 'QA_REVIEWER_RELIABILITY',
  SPEECH_STATS: 'SPEECH_STATS',
  SPEECH_TREATMENTS: 'SPEECH_TREATMENTS',
  SPEECH_ASSESSMENTS: 'SPEECH_ASSESSMENTS',
  SURGERY_STATS: 'SURGERY_STATS',
  SURGICAL_QA: 'SURGICAL_QA',
  SURGICAL_TREATMENTS_COMPACT: 'SURGICAL_TREATMENTS_COMPACT',
  SURGICAL_TREATMENTS: 'SURGICAL_TREATMENTS',
  SURGICAL_FOLLOW_UP_VISITS: 'SURGICAL_FOLLOW_UP_VISITS',
  YEAR_TO_DATE: 'YEAR_TO_DATE',
  ORTHODONTIC_STATS: 'ORTHODONTIC_STATS',
  ORTHODONTIC_STATS_INDIA: 'ORTHODONTIC_STATS',
  PARTNER_SURGERY_COUNTS: 'PARTNER_SURGERY_COUNTS',
  PARTNER_SURGERY_COUNTS_FOUNDATION_CONNECT: 'PARTNER_SURGERY_COUNTS_FOUNDATION_CONNECT',
  NUTRITION_FORMS: 'NUTRITION_FORMS',
  NUTRITION_STATS: 'NUTRITION_STATS',
  QA_EVALUATOR_REVIEWS_SURGERY: 'NUTRITION_STATS',
  QA_EVALUATOR_REVIEWS_SPEECH: 'QA_EVALUATOR_REVIEWS_SPEECH',
  QA_EVALUATOR_REVIEWS_ORTHO: 'QA_EVALUATOR_REVIEWS_ORTHO'
};
export type ReportType = keyof typeof AllReportTypes;

export interface AdditionalReportValidators {
  maxDatesDifferenceInYears: number | null;
}

export interface ReportConfig {
  type: ReportType;
  filters: Partial<ReportFilters>;
  downloadOptions: Partial<ReportDownloadOptions>;
  additionalValidators?: Partial<AdditionalReportValidators>;
}

type RequiredDeep<T> = {
  [K in keyof T]: Required<T[K]>;
};

export type ReportFullConfig = RequiredDeep<ReportConfig>;

export type SpeechOptionsFilter = 'speechAssessmentStates' | 'speechTreatmentStates' | 'speechAssessmentRecommendations';

export type ReportFilterName =
  | 'ages'
  | 'date'
  | 'deIdentification'
  | 'qaOnly'
  | 'genders'
  | 'treatmentCenters'
  | 'partners'
  | 'countries'
  | 'crms'
  | 'crmRegions'
  | 'orthodonticInterventions'
  | 'zoneCountries'
  | 'provinces'
  | 'zoneProvinces'
  | 'includePhotos'
  | 'latestCases'
  | 'mohGrades'
  | 'recordDate'
  | 'qaStages'
  | 'referringOrganization'
  | 'rejectedRecords'
  | 'reportedComplications'
  | 'groupByScope'
  | 'orthocasesTypes'
  | 'reviewers'
  | 'surgeons'
  | 'status'
  | 'practitioners'
  | 'orthodontists'
  | 'surgicalInterventions'
  | 'qaSurgicalInterventions'
  | 'qaSurgeryStatuses'
  | 'qaPreRanks'
  | 'qaPostRanks'
  | 'periodType'
  | 'periodPresets'
  | 'qualityAssurance'
  | 'primaryLipNoseUnilateral'
  | 'primaryLipNoseBilateral'
  | 'lipNoseRevision'
  | 'primaryCleftPalate'
  | 'fistulaRepair'
  | 'secondaryCleftPalate'
  | 'other'
  | 'velopharyngealDysfunction'
  | 'alveolarBoneGraft'
  | 'region'
  | 'speechAssessmentStatuses'
  | 'speechAssessmentStates'
  | 'speechTreatmentStates'
  | 'speechAssessmentRecommendations'
  | 'statsType'
  | 'speechTreatmentStatuses'
  | 'treatmentNotCompliant'
  | 'ageGroups'
  | 'nutritionists'
  | 'treatmentType'
  | 'allSelectedTreatmentTypes'
  | 'orthoStatuses'
  | 'nutritionStatuses'
  | 'goodCaseExample'
  | 'needsImmediateAttention'
  | 'poorPatientSelection'
  | 'preGrading'
  | 'postGrading'
  | 'ageGroupsWithTreatmentStatuses';

export type ReportBooleanFields =
  | 'deIdentified'
  | 'qaOnly'
  | 'includePhotos'
  | 'referringOrganization'
  | 'rejectedRecords'
  | 'reportedComplications'
  | 'treatmentNotCompliant'
  | 'allSelectedTreatmentTypes'
  | 'goodCaseExample'
  | 'needsImmediateAttention'
  | 'poorPatientSelection';

export type ReportCheckboxesFilters =
  | 'mohGrades'
  | 'treatmentStatus'
  | 'qaStages'
  | 'ageGroups'
  | 'orthoCategories'
  | 'preGrading'
  | 'postGrading';

export type ReportMultiSelectionFilters = 'surgeons' | 'practitioners' | 'orthodontists' | 'reviewers' | 'status' | 'nutritionists';

export interface CheckboxOption {
  label: string;
  name?: string;
  value: string;
}

export interface CommonReportDictionaryItem {
  label: string;
  value: string;
  tooltipText?: string;
}

export interface SectionedCommonReportDictionaryItem {
  [key: string]: CommonReportDictionaryItem[];
}

export interface ReportDictionaryItemBase extends Omit<CommonReportDictionaryItem, 'value'> {}

export interface ReportDictionaryTreeSubsection {
  name: string;
  values: CommonReportDictionaryItem[];
}

export interface ReportDictionaryTreeItem extends ReportDictionaryItemBase {
  value: string;
  subSections?: ReportDictionaryTreeSubsection[];
  description?: string[];
}

export type ReportDictionaryTree<K extends string = string> = Record<K, ReportDictionaryTreeItem>;

export type SpeechStageDictionaryTree = ReportDictionaryTree<'speechAssessment' | 'speechTreatment'>;

interface RepairTypeDictionary {
  primaryLipNoseUnilateral: CommonReportDictionaryItem[];
  primaryLipNoseBilateral: CommonReportDictionaryItem[];
  lipNoseRevision: CommonReportDictionaryItem[];
  primaryCleftPalate: CommonReportDictionaryItem[];
  fistulaRepair: CommonReportDictionaryItem[];
  secondaryCleftPalate: CommonReportDictionaryItem[];
  other: CommonReportDictionaryItem[];
  velopharyngealDysfunction: CommonReportDictionaryItem[];
  alveolarBoneGraft: CommonReportDictionaryItem[];
}

export interface ReportsDictionariesResponse {
  genders: CommonReportDictionaryItem[];
  latestCases: CommonReportDictionaryItem[];
  periodTypes: CommonReportDictionaryItem[];
  surgicalInterventions: CommonReportDictionaryItem[];
  qaStages: CommonReportDictionaryItem[];
  ageGroups: CommonReportDictionaryItem[];
  recordTypes: RadioGroupOptions;
  surgeryStatus: CommonReportDictionaryItem[];
  surgeons: CommonReportDictionaryItem[];
  practitioners: CommonReportDictionaryItem[];
  nutritionists: CommonReportDictionaryItem[];
  preRanks: CommonReportDictionaryItem[];
  postRanks: CommonReportDictionaryItem[];
  treatmentStatuses: CommonReportDictionaryItem[];
  statuses: CommonReportDictionaryItem[];
  exclusions: CommonReportDictionaryItem[];
  groupByData: CommonReportDictionaryItem[];
  qualityAssuranceSurgicalInterventions: CommonReportDictionaryItem[];
  repairTypes: RepairTypeDictionary;
  orthoPsioCategories: SectionedCommonReportDictionaryItem;
  orthoMdCategories: SectionedCommonReportDictionaryItem;
  orthoPdCategories: SectionedCommonReportDictionaryItem;
  orthodontists: CommonReportDictionaryItem[];
  reviewers: CommonReportDictionaryItem[];
  speechAssessmentStatuses: ReportDictionaryTreeItem[];
  speechTreatmentStatuses: ReportDictionaryTreeItem[];
  mohGrades: CommonReportDictionaryItem[];
  photoTypes: CommonReportDictionaryItem[];
  speechAssessmentStates: CommonReportDictionaryItem[];
  speechAssessmentRecommendations: CommonReportDictionaryItem[];
  speechTreatmentStates: CommonReportDictionaryItem[];
  orthoRecordDates: ReportDictionaryTree;
  statsTypes: RadioGroupOptions;
  orthoPreGrading: CommonReportDictionaryItem[];
  orthoPostGrading: CommonReportDictionaryItem[];
  ageGroupsWithTreatmentStatuses: ReportDictionaryTreeItem[];
}

export interface ReportDictionaries extends ReportsDictionariesResponse {
  organizationsTree?: OrgTreeFilterModel;
}

export interface ReportPeriod {
  controlName: string;
  label: string;
  selected: boolean;
  year: string;
  unitValue: string;
  value: string;
}

export interface ReportPeriodGroup {
  year: string;
  periods: ReportPeriod[];
}

export interface ReportPeriodQuarter {
  quarterNumber: number;
  months: ReportPeriod[];
}

export interface ReportPeriodQuarterGroup {
  year: string;
  quarters: ReportPeriodQuarter[];
}

export type ReportFilterChangeValue = Array<string | number | boolean>;

export type ReportFilterChangePayload = Record<string, ReportFilterChangeValue>;

export interface ReportFilterChange<T = Record<string, any[]>, R = ReportFilterName> {
  filterName: R;
  payload: T;
}

export interface ReportTableParams {
  autoTableDisplay: boolean;
}

export interface ReportFiltersParams {
  genders?: string[];
  ageFrom?: number;
  ageTo?: number;
  deIdentification?: string[];
  goodCaseExample?: string[];
  qaOnly?: string[];
  latestCases?: string;
  qaSurgicalIntervention?: string[];
  qaSurgeryStatus?: string[];
  qaPreRank?: string[];
  qaPostRank?: string[];
  dateFrom?: string;
  dateTo?: string;
  recordType?: string;
  scope?: string;
  surgeons?: string[];
  practitioners?: string[];
  orthodontists?: string[];
  nutritionists?: string[];
  surgicalInterventions?: string[];
  countries?: string[];
  crms?: string[];
  crmRegions?: string[];
  zoneCountries?: string[];
  provinces?: string[];
  zoneProvinces?: string[];
  partners?: string;
  treatmentCenters?: string;
  treatmentStatuses?: string[];
  statsType?: string;
}
export interface ReportFiltersModel {
  recordDate?: RecordTypeChangePayload;
  orthoStatuses?: OrthoStatusesChangePayload;

  nutritionStatuses?: NutritionStatusesChangePayload;

  // TODO#30147: add strict types for all filters
  [key: string]: any;
}

// Form group models
export interface PeriodPresetsGroupModel {
  calendarYears: { [key: string]: boolean };
  fiscalYears: { [key: string]: boolean };
  quarters: { [key: string]: boolean };
  months: { [key: string]: boolean };
}
export interface AgeFilterModel {
  from: number;
  to: number;
}

// Filter change event models
export interface AgeChangePayload extends ReportFilterChangePayload {
  ageFrom: number[];
  ageTo: number[];
}

export interface RecordDatePayload extends ReportFilterChangePayload {
  dateFrom: string[];
  dateTo: string[];
}

export interface RecordTypeChangePayload extends ReportFilterChangePayload {
  recordType: number[];
}

export interface LatestCasesChangePayload extends ReportFilterChangePayload {
  latestCases: string[];
}
export interface OrthoStatusesChangePayload extends ReportFilterChangePayload {
  psioAssessmentStates: string[];
  psioTreatmentStates: string[];
  pdAssessmentStates: string[];
  pdMidAssessmentStates: string[];
  pdTreatmentStates: string[];
  mdAssessmentStates: string[];
  mdMidAssessmentStates: string[];
  mdTreatmentStates: string[];
}

export interface NutritionStatusesChangePayload extends ReportFilterChangePayload {
  nutritionAssessmentStates: string[];
  nutritionFollowUpStates: string[];
}

export interface PeriodTypeChangePayload extends ReportFilterChangePayload {
  periodType: string[];
}

export interface StatsTypeChangePayload extends ReportFilterChangePayload {
  statsType: string[];
}

export interface PeriodPresetsChangePayload extends ReportFilterChangePayload {
  calendarYears: string[];
  fiscalYears: string[];
  calendarMonths: string[];
  fiscalQuarters: string[];
}

export type BooleanChangePayload = Partial<Record<ReportBooleanFields, boolean[]>>;

export interface GroupByScopeChangePayload extends ReportFilterChangePayload {
  groupByData: string[];
}

export type ReportCheckboxesPayload = Partial<Record<ReportCheckboxesFilters, string[]>>;
