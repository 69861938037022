import { PermissionEnum } from '@/src/app/core/permissions/permission.enum';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { OrgTreeFilterModel } from '../../models/org-tree-filter.model';
import { SearchFiltersBase } from '../../models/search-filters-base.model';
import { SearchFilterService } from '../../services/search-filter.service';

@Component({
  selector: 'stx-search-base',
  template: ''
})
export abstract class SearchBaseComponent extends BaseComponent {
  readonly PermissionEnum = PermissionEnum;
  searchFormGroup: UntypedFormGroup;
  orgTreeFiltersData: OrgTreeFilterModel;

  protected abstract getSearchFilters(): SearchFiltersBase;
  protected constructor(private searchFilterService: SearchFilterService) {
    super();
    this.searchFilterService.config.searchFiltersGetter = () => this.getSearchFilters();
  }

  initiateSearch() {
    this.searchFilterService.initiateSearch();
  }
}
