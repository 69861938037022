import { Patient } from '@/src/app/features/patient/models/patient.model';
import { PatientService } from '@/src/app/features/patient/patient.service';
import { BaseFormElementComponent } from '@/src/app/shared/components/base-treatment-form/base-form-element.component';
import { FormActionFlowControl } from '@/src/app/shared/components/base-treatment-form/form-action-flow-control.models';
import { TreatmentType } from '@/src/app/shared/components/treatment/treatment.enum';
import { FormType } from '@/src/app/shared/enums/form-type.enum';
import { TreatmentCenterDictionary } from '@/src/app/shared/models/treatment-center.model';
import { WsHelperService } from '@/src/app/shared/services/ws-helper.service';
import { populateWithDefaults, touchAndPartiallyValidateControls } from '@/src/app/shared/validation/partial-validation.utils';
import {
  registerUpdateValueAndValidityForAllControls,
  resetFormGroupValidationState,
  updateValueAndValidityForAllControls
} from '@/src/app/shared/validation/validation.utils';
import { AsyncButtonClickAction } from '@/src/app/utils/button.utils';
import { getToday } from '@/src/app/utils/date.utils';
import { scrollToFirstInvalidField, scrollToTop } from '@/src/app/utils/scroll.utils';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '@shared/components/spinner/service/spinner.service';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { BaseTreatment, TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';
import { patientDetailsRoute } from '@utils/routing.utils';
import { Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { emptyPartialValidationConfig, PartialValidationConfig } from '../../validation/partial-validation-config.model';

/** Form component with associated formGroup and partial validation logic */

@Component({ template: '' })
export abstract class BaseTreatmentFormComponent<T extends BaseTreatment> extends BaseFormElementComponent {
  readonly today = getToday();
  formType: FormType;
  treatmentType: TreatmentType;
  @Output() dataSaved = new EventEmitter<void>();
  get treatmentFormGroup() {
    return this.formGroup;
  }
  @Input() patient: Patient;
  tcList: TreatmentCenterDictionary[] = [];

  private _saveConfig: PartialValidationConfig = emptyPartialValidationConfig;
  protected _discontinueConfig: PartialValidationConfig = emptyPartialValidationConfig;

  protected constructor(
    protected readonly elementRef: ElementRef,
    protected readonly zone: NgZone,
    private readonly snackBarService: SnackBarService,
    protected readonly spinnerService: SpinnerService,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly globalErrorHandlerService: GlobalErrorHandlerService,
    protected readonly wsHelper: WsHelperService,
    protected readonly patientService: PatientService
  ) {
    super();
  }

  protected configureForm(
    treatmentFormGroup: UntypedFormGroup,
    saveConfig?: PartialValidationConfig,
    discontinueConfig?: PartialValidationConfig
  ) {
    this.formGroup = treatmentFormGroup;
    registerUpdateValueAndValidityForAllControls(this.formGroup, this.subSink);
    this._saveConfig = populateWithDefaults(this.formGroup, saveConfig ?? this._saveConfig);
    this._discontinueConfig = populateWithDefaults(this.formGroup, discontinueConfig ?? this._discontinueConfig);
  }

  protected beforeValidationAsync(): Observable<FormActionFlowControl> {
    return of({ proceed: true });
  }

  protected beforeValidationSync(): void {
    resetFormGroupValidationState(this.treatmentFormGroup);
    this.changeDetector.detectChanges(); // we need to detect changes for template-driven validators on e.g. date fields
  }

  protected afterSubmitValidationAsync(): Observable<FormActionFlowControl> {
    return of({ proceed: true });
  }

  onSubmitButtonClicked(): AsyncButtonClickAction {
    return () => {
      return this.beforeValidationAsync().pipe(
        mergeMap(beforeValidationResult => {
          if (!beforeValidationResult.proceed) {
            return this.onSubmitOrSaveValidationFails();
          }
          this.beforeValidationSync();

          if (!this.refreshValidityStateForSubmit()) {
            return this.onSubmitOrSaveValidationFails();
          }

          return this.afterSubmitValidationAsync().pipe(
            mergeMap(afterValidationResult => {
              if (afterValidationResult.proceed) {
                return this.onSubmitValidationSuccess();
              } else {
                return this.onSubmitOrSaveValidationFails();
              }
            })
          );
        })
      );
    };
  }

  protected afterSaveValidationAsync(): Observable<FormActionFlowControl> {
    return of({ proceed: true });
  }

  onSaveButtonClicked(): AsyncButtonClickAction {
    return () => {
      return this.beforeValidationAsync().pipe(
        mergeMap(beforeValidationResult => {
          if (!beforeValidationResult.proceed) {
            return this.onSubmitOrSaveValidationFails();
          }
          this.beforeValidationSync();

          if (!this.refreshValidityStateForSave()) {
            return this.onSubmitOrSaveValidationFails();
          }

          return this.afterSaveValidationAsync().pipe(
            mergeMap(afterValidationChecksResult => {
              if (afterValidationChecksResult.proceed) {
                return this.onSaveValidationSuccess();
              } else {
                return this.onSubmitOrSaveValidationFails();
              }
            })
          );
        })
      );
    };
  }

  protected afterDiscontinueValidationAsync(): Observable<FormActionFlowControl> {
    return of({ proceed: true });
  }

  onDiscontinue(): AsyncButtonClickAction {
    return () => {
      return this.beforeValidationAsync().pipe(
        mergeMap(beforeValidationResult => {
          if (!beforeValidationResult.proceed) {
            return this.onDiscontinueValidationFailed();
          }
          this.beforeValidationSync();

          if (!this.refreshValidityStateForDiscontinue()) {
            return this.onDiscontinueValidationFailed();
          }

          return this.afterDiscontinueValidationAsync().pipe(
            mergeMap(afterValidationChecksResult => {
              if (afterValidationChecksResult.proceed) {
                return this.onDiscontinueValidationSuccess();
              } else {
                return this.onDiscontinueValidationFailed();
              }
            })
          );
        })
      );
    };
  }

  protected refreshValidityStateForSubmit(): boolean {
    this.treatmentFormGroup.markAllAsTouched();
    updateValueAndValidityForAllControls(this.treatmentFormGroup);
    return this.treatmentFormGroup.valid;
  }

  protected refreshValidityStateForSave(): boolean {
    return touchAndPartiallyValidateControls(this.formGroup, this._saveConfig);
  }

  protected refreshValidityStateForDiscontinue(): boolean {
    return touchAndPartiallyValidateControls(this.formGroup, this._discontinueConfig);
  }

  protected onSubmitValidationSuccess(): Observable<T> {
    const dataToBeSaved = this.getTreatmentDataToSave();
    this.spinnerService.addTask();
    return this.callSubmit(dataToBeSaved).pipe(
      tap({
        next: submittedData => {
          this.setTreatmentData(submittedData);
          this.router.navigate([this.getViewRoute(submittedData.id)], {
            queryParams: { patientId: this.getPatientId() }
          });
          this.dataSaved.emit();
          this.onTreatmentSubmitted();
          this.spinnerService.removeTask();
          this.changeDetector.markForCheck();
        },
        error: error => {
          this.spinnerService.removeTask();
          this.handleSubmitError(error);
        }
      })
    );
  }

  protected handleSubmitError(error: HttpErrorResponse) {
    this.globalErrorHandlerService.handleErrorAndInformUserWithAlertDialog(error);
  }

  protected onSubmitOrSaveValidationFails(): Observable<void> {
    scrollToFirstInvalidField(this.elementRef, this.zone);
    this.changeDetector.markForCheck();
    return of(null);
  }

  protected onSaveValidationSuccess(): Observable<T> {
    this.spinnerService.addTask();
    return this.callSave(this.getTreatmentDataToSave()).pipe(
      tap({
        next: newData => {
          this.setTreatmentData(newData);
          this.router.navigate([this.getEditRoute(newData.id)], {
            queryParams: { patientId: this.getPatientId() }
          });
          this.dataSaved.emit();
          this.onTreatmentSaved();
          this.spinnerService.removeTask();
          this.changeDetector.markForCheck();
        },
        error: error => {
          this.spinnerService.removeTask();
          this.handleSaveError(error);
        }
      })
    );
  }

  protected handleSaveError(error: HttpErrorResponse) {
    this.globalErrorHandlerService.handleErrorAndInformUserWithAlertDialog(error);
  }

  protected onDiscontinueValidationSuccess(): Observable<T> {
    this.spinnerService.addTask();
    return this.callDiscontinue(this.getTreatmentDataToSave()).pipe(
      tap({
        next: savedData => {
          this.setTreatmentData(savedData);
          this.router.navigate([this.getViewRoute(savedData.id)], {
            queryParams: { patientId: this.getPatientId() }
          });
          this.dataSaved.emit();
          this.onTreatmentDiscontinued();
          this.spinnerService.removeTask();
          this.changeDetector.markForCheck();
        },
        error: error => {
          this.spinnerService.removeTask();
          this.globalErrorHandlerService.handleErrorAndInformUserWithAlertDialog(error);
        }
      })
    );
  }

  protected onDiscontinueValidationFailed(): Observable<void> {
    scrollToFirstInvalidField(this.elementRef, this.zone);
    this.changeDetector.markForCheck();
    return of(null);
  }

  protected onTreatmentSubmitted(): void {
    // method does not have base implementation - needs to be overridden
  }

  protected onTreatmentSaved(): void {
    this.snackBarService.notify('saved_successfully', 'save_submit');
    scrollToTop();
  }
  protected onTreatmentDiscontinued(): void {
    this.onTreatmentSaved();
  }

  /*
  TODO#5872 All these action-related protected methods are created just to reflect current usage.
   Further plan is to extract actions/ws calls to a separate component for handling actions so form components will be more dumb (only managing fields and validation).

   TODO#13749 assign the service with forms API implementation inside the form component, move all "call" here
   */
  protected abstract getTreatmentId(): TreatmentId;
  protected abstract getPatientId(): number;
  protected abstract getEditRoute(treatmentId: TreatmentId): string;
  protected abstract getViewRoute(treatmentId: TreatmentId): string;
  protected abstract getTreatmentDataToSave(): T;
  protected abstract setTreatmentData(data: T): void;
  protected abstract callSave(data: T): Observable<T>;
  protected callDiscontinue(data: T): Observable<T> {
    throw new Error('Unexpected action');
  }
  protected abstract callSubmit(data: T): Observable<T>;
  protected abstract callDelete(id: TreatmentId): Observable<void>;
  protected abstract callUnlock(id: TreatmentId): Observable<void>;

  readonly delete = (): void => {
    this.wsHelper.callWithSpinner(this.callDelete(this.getTreatmentId())).subscribe({
      next: () => {
        this.router.navigate(patientDetailsRoute(this.getPatientId()));
      }
    });
  };

  readonly unlock = (): void => {
    this.wsHelper.callWithSpinner(this.callUnlock(this.getTreatmentId())).subscribe({
      next: () => {
        this.router.navigate([this.getEditRoute(this.getTreatmentId())], {
          queryParams: { patientId: this.getPatientId() }
        });
      }
    });
  };

  protected handleWsCall<Type>(observable: Observable<Type>, callback?: (response: Type) => void, redirectOn404 = false): void {
    this.subSink.sink = this.wsHelper.callWithSpinner(observable, { redirectOn404StatusCode: redirectOn404 }).subscribe({
      next: result => {
        if (callback) {
          callback(result);
        }
      }
    });
  }

  protected getPatientTreatmentCenters(): void {
    if (this.patient) {
      this.subSink.sink = this.wsHelper
        .callWithSpinner(this.patientService.getPatientTreatmentCenters(this.patient.id, this.treatmentType))
        .subscribe({
          next: tcList => {
            this.tcList = tcList;
            this.changeDetector.markForCheck();
          }
        });
    }
  }
}
