import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PermissionEnum } from 'src/app/core/permissions/permission.enum';
import { FormGuardService } from '@shared/services/form-guard.service';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';

@Injectable({ providedIn: 'root' })
//TODO #41042 Update deprecated  class-based Route guards in favor of functional guards
export class PermissionGuard {
  constructor(private router: Router, private currentUserService: CurrentUserService, private formGuardService: FormGuardService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkPermissions(route);
  }

  checkPermissions(route: ActivatedRouteSnapshot): boolean | UrlTree {
    let userPermissions: string[];
    const user = this.currentUserService.getCurrentUser();
    let canActivate = false;
    if (user) {
      userPermissions = user.permissions.map(x => x.name);
      canActivate = userPermissions.includes(route.data.permission);
    }
    if (!canActivate) {
      const redirectTree = this.fallbackToAllowedRoute(route, userPermissions);
      if (redirectTree) {
        return redirectTree;
      }
    }
    return canActivate;
  }

  private fallbackToAllowedRoute(route: ActivatedRouteSnapshot, userPermissions: string[]): void | UrlTree {
    if (route.data.permission === PermissionEnum.VIEW_DASHBOARD_MAIN) {
      this.dashboardRedirects(userPermissions);
    } else if (this.formGuardService.canFallbackToReadonly(route, userPermissions)) {
      return this.formGuardService.getReadonlyPathFromEditPath(route);
    } else {
      this.router.navigateByUrl('/');
    }
  }

  private dashboardRedirects(userPermissions: string[]) {
    if (userPermissions.includes(PermissionEnum.VIEW_DASHBOARD_REDIRECT_USERS)) {
      this.router.navigateByUrl('/users');
    }
    if (userPermissions.includes(PermissionEnum.VIEW_DASHBOARD_REDIRECT_PATIENTS)) {
      this.router.navigateByUrl('/patients');
    }
  }
}
