import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, first } from 'rxjs/operators';
import {
  ReportConfig,
  ReportFilterChange,
  ReportFilters,
  ReportFiltersModel,
  ReportFullConfig,
  ReportsDictionariesResponse,
  ReportType
} from '../models/report-filters.model';
import { ReportDictionariesService } from './report-dictionaries.service';
import { ReportFiltersFormGeneratorService } from './report-filters-form-generator.service';
import { ReportPermissionsService } from './report-permissions.service';
import { HttpClient } from '@angular/common/http';
import { ReportRegions } from '@src/app/features/reports/models/report-regions.model';
import { ArrayUtils } from '@utils/array.utils';
import { latestCasesShowAllValue } from '@src/app/features/reports/components/report-filters/components/latest-cases-filter/latest-cases-filter.component';
import { WsHelperService } from '@shared/services/ws-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private static readonly defaultConfig: ReportFullConfig = {
    type: '' as ReportType,
    downloadOptions: {
      excel: false,
      excelAdmin: false,
      excelAdminId: false,
      excelAgeGroups: false,
      excelAdminAgeGroups: false,
      excelAdminIdAgeGroups: false
    },
    filters: {
      age: false,
      date: false,
      deIdentification: false,
      qaOnly: false,
      gender: false,
      geography: false,
      includePhotos: false,
      latestCases: false,
      organizationsTree: false,
      periodType: false,
      periodPresets: false,
      photoTypes: false,
      qaStages: false,
      referringOrganization: false,
      reportedComplications: false,
      qualityAssurance: false,
      practitioners: false,
      orthodontists: false,
      recordDate: false,
      orthoRecordDates: false,
      rejectedRecords: false,
      repairTypes: false,
      speechAssessmentStates: false,
      speechTreatmentStates: false,
      status: false,
      speechAssessmentStatuses: false,
      speechTreatmentStatuses: false,
      speechAssessmentRecommendations: false,
      orthoTypes: false,
      orthoStatuses: false,
      surgeons: false,
      surgicalIntervention: false,
      groupByScope: false,
      treatmentNotCompliant: false,
      orthoCasesTypes: false,
      reviewers: false,
      mohGrades: false,
      treatmentStatus: false,
      statsType: false,
      exclusions: false,
      nutritionists: false,
      ageGroups: false,
      nutritionTypes: false,
      nutritionStatuses: false,
      orthoQaGrading: false,
      ageGroupsWithTreatmentStatuses: false
    },
    additionalValidators: {
      maxDatesDifferenceInYears: null
    }
  };

  private filtersVisible = true;
  private filtersReadySubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly filtersReady$: Observable<boolean> = this.filtersReadySubject.asObservable();
  private filtersVisibleSubject: BehaviorSubject<boolean> = new BehaviorSubject(this.filtersVisible);
  public readonly filtersVisible$: Observable<boolean> = this.filtersVisibleSubject.asObservable();
  public readonly initialized$: Observable<boolean> = this.filtersReadySubject.asObservable().pipe(delay(0));

  config: ReportFullConfig;
  model: ReportFiltersModel;
  reportFiltersFormGroup: UntypedFormGroup;

  constructor(
    private reportFiltersFormGeneratorService: ReportFiltersFormGeneratorService,
    private reportDictionariesService: ReportDictionariesService,
    private reportPermissionsService: ReportPermissionsService,
    private httpClient: HttpClient,
    private wsHelperService: WsHelperService
  ) {}

  prepare(config: ReportConfig) {
    const permittedConfig = this.reportPermissionsService.getAllowedReportConfig(config);

    this.filtersReadySubject.next(false);
    this.model = new Map();
    this.setConfig(permittedConfig);

    return this.wsHelperService
      .callWithSpinner(
        this.reportDictionariesService.prepareRequiredDictionaries(this.config.filters as ReportFilters, this.config.type).pipe(first())
      )
      .subscribe({
        next: ([reportDictionaries]) => {
          this.reportFiltersFormGroup = this.reportFiltersFormGeneratorService.generateFormGroup(
            this.config,
            reportDictionaries as ReportsDictionariesResponse
          );
          this.filtersReadySubject.next(true);
        }
      });
  }

  setFilterVisibility(filterName: keyof ReportFilters, isVisible: boolean) {
    this.config.filters[filterName] = isVisible;
  }

  showFilters() {
    this.filtersVisible = true;
    this.filtersVisibleSubject.next(this.filtersVisible);
  }

  toggleFiltersVisibility() {
    this.filtersVisible = !this.filtersVisible;
    this.filtersVisibleSubject.next(this.filtersVisible);
  }

  hideFilters() {
    this.filtersVisible = false;
    this.filtersVisibleSubject.next(this.filtersVisible);
  }

  onFilterChange(change: ReportFilterChange) {
    this.model?.set(change.filterName, Object.assign({}, change.payload));
  }

  private setConfig(config: ReportConfig) {
    this.config = {
      type: config.type,
      downloadOptions: Object.assign({}, ReportService.defaultConfig.downloadOptions, config.downloadOptions),
      filters: Object.assign({}, ReportService.defaultConfig.filters, config.filters),
      additionalValidators: Object.assign({}, ReportService.defaultConfig.additionalValidators, config.additionalValidators)
    };
  }

  getUserRegionalReportsVisibility(): Observable<Map<ReportRegions, boolean>> {
    return this.httpClient.get<Map<ReportRegions, boolean>>('api/orgtree/regional-reports-visibility');
  }

  latestCasesFilterActive(): boolean {
    const selectedLatestCases = (this.model as Map<string, any>).get('latestCases')?.latestCases ?? [];
    return selectedLatestCases.length === 0 || ArrayUtils.contains(selectedLatestCases, latestCasesShowAllValue);
  }
}
