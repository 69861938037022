<div class="flex-column-container">
  <form [formGroup]="searchBarForm" class="flex-row-container wrap align-middle search-container">
    <mat-form-field appearance="outline" class="no-mat-form-field-padding search-filter-input-container">
      <input
        data-testid="input-search-bar"
        formControlName="searchBar"
        matInput
        type="search"
        [maxlength]="maxCharactersLimitForQuery"
        (keyup.enter)="onSearchButtonClicked()"
        #searchInput
      />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <a>
      <mat-icon (click)="toggleFilters()">tune</mat-icon>
    </a>
    <stx-submit-button class="block" (action)="onSearchButtonClicked()">{{ 'search' | translate }}</stx-submit-button>

    <div class="flex-row-container p-r-0 gap-25 align-middle">
      <div *ngIf="config.isCsvAvailable">
        <a>
          <mat-icon
            [matTooltip]="'download_as_csv' | translate"
            [matTooltipClass]="'tooltip'"
            (click)="onCsvExportClicked()"
            matTooltipPosition="above"
          >
            save_alt
          </mat-icon>
        </a>
      </div>
      <ng-container *ngIf="config.routerLink">
        <ng-container *ngIf="config.routerLink?.permission">
          <ng-container *stxPermitted="config.routerLink.permission">
            <ng-container *ngTemplateOutlet="routerLink"></ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!config.routerLink?.permission">
          <ng-container *ngTemplateOutlet="routerLink"></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </form>
  <div class="data-section m-t-25" [hidden]="!isExpanded">
    <stx-form-panel>
      <ng-content> </ng-content>
    </stx-form-panel>
  </div>
</div>

<ng-template #routerLink>
  <a [routerLink]="config.routerLink?.url" class="flex-row-container align-middle">
    <mat-icon class="m-r-5">{{ config.routerLink.icon }}</mat-icon>
    <div>{{ config.routerLink.text | translate }}</div>
  </a>
</ng-template>
