import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ReportConfig } from '../../components/report-filters/models/report-filters.model';
import { ReportFiltersModule } from '../../components/report-filters/report-filters.component';
import { ReportTableDataService } from '../../components/report-filters/services/report-table-data.service';
import { ReportService } from '../../components/report-filters/services/report.service';
import { ReportHeaderComponentModule } from '../../components/report-header/report-header.component';
import { SurgicalTreatmentCompactReportTableComponentModule } from './surgical-treatment-compact-report-table/surgical-treatment-compact-report-table.component';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';

@Component({
  selector: 'stx-surgical-treatments-compact-report',
  templateUrl: './surgical-treatments-compact-report.component.html'
})
export class SurgicalTreatmentsCompactReportComponent {
  readonly reportConfig: ReportConfig = {
    type: 'SURGICAL_TREATMENTS_COMPACT',
    downloadOptions: {},
    filters: {
      age: true,
      deIdentification: true,
      gender: true,
      geography: true,
      latestCases: true,
      organizationsTree: true,
      recordDate: true,
      qualityAssurance: true,
      surgicalIntervention: true,
      surgeons: true
    }
  };

  showPagination = true;

  constructor(public reportService: ReportService, private reportTableDataService: ReportTableDataService) {}

  applyFilters(pagination: PaginationSortingModel) {
    this.showPagination = this.reportService.latestCasesFilterActive();
    this.reportTableDataService.prepareList('SURGICAL_TREATMENTS_COMPACT', this.reportService.model, pagination);
  }
}
@NgModule({
  declarations: [SurgicalTreatmentsCompactReportComponent],
  imports: [
    ReportFiltersModule,
    ReportHeaderComponentModule,
    CommonModule,
    SurgicalTreatmentCompactReportTableComponentModule,
    TranslateModule,
    GeneralCommonsModule
  ],
  exports: [SurgicalTreatmentsCompactReportComponent]
})
export class SurgicalTreatmentsCompactReportModule {}
