import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfigurationService } from '@shared/services/configuration.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
//TODO #41042 Update deprecated  class-based Route guards in favor of functional guards
export class RegionalRedirectGuard {
  constructor(private readonly configService: ConfigurationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.configService.getRedirectUrl().then(url => {
      if (url) {
        window.location.href = url;
      }
      return Promise.resolve(true);
    });
  }
}
