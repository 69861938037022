import { MidAssessment } from '@/src/app/features/ortho/models/mid-assessment.model';
import { OrthoStageValidationData } from '@/src/app/features/ortho/models/ortho-stage-validation-data.model';
import { OrthoFormApiBase } from '@/src/app/features/ortho/shared/ortho-form-api-base';
import { BaseTreatment, TreatmentId } from '@/src/app/features/surgical/models/base-treatment.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export abstract class OrthoMidAssessmentFormApi<
  OrthoMidAssessmentModel extends BaseTreatment = MidAssessment
> extends OrthoFormApiBase<OrthoMidAssessmentModel> {
  constructor(httpClient: HttpClient, apiRoute: string) {
    super(httpClient, apiRoute);
  }

  override getValidationData(patientId: number, midAssessmentId?: TreatmentId): Observable<OrthoStageValidationData> {
    const params = midAssessmentId ? new HttpParams().set('midAssessmentId', midAssessmentId.toString()) : null;
    return this.httpClient.get<OrthoStageValidationData>(`${this.baseUrl}/${patientId}/validation-data`, { params });
  }
}
