import { PermissionEnum } from '@/src/app/core/permissions/permission.enum';
import { orthoQaRoute, speechQaRoute, surgicalQaRoute } from '@/src/app/utils/routing.utils';
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthService } from '../../authentication/services/auth.service';

@Injectable({ providedIn: 'root' })
//TODO #41042 Update deprecated  class-based Route guards in favor of functional guards
export class DashboardPathGuard {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(): UrlTree | boolean {
    if (!this.authService.checkUserPermission(PermissionEnum.VIEW_DASHBOARD_MAIN)) {
      if (this.authService.checkUserPermission(PermissionEnum.VIEW_DASHBOARD_REVIEW_SURGICAL)) {
        return this.router.parseUrl(`/${surgicalQaRoute}`);
      }
      if (this.authService.checkUserPermission(PermissionEnum.VIEW_DASHBOARD_REVIEW_ORTHO)) {
        return this.router.parseUrl(`/${orthoQaRoute}`);
      }
      if (this.authService.checkUserPermission(PermissionEnum.VIEW_DASHBOARD_REVIEW_SPEECH)) {
        return this.router.parseUrl(`/${speechQaRoute}`);
      }
    }
    return true;
  }
}
