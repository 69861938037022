import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';

@Injectable({ providedIn: 'root' })
//TODO #41042 Update deprecated  class-based Route guards in favor of functional guards
export class AuthGuard {
  constructor(private router: Router, private currentUserService: CurrentUserService) {}

  canLoad(route: Route, segments: UrlSegment[]) {
    return this.isAuthenticated();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.isAuthenticated();
  }

  isAuthenticated(): boolean {
    const user = this.currentUserService.getCurrentUser();
    return !!user;
  }
}
