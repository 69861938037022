import { orthoTypeToTranslationKeyMap } from '@/src/app/features/ortho/utils/ortho.utils';
import { DictionaryTranslation } from '@/src/app/features/patient/models/patient.model';
import { BaseReportTableComponent } from '@/src/app/features/reports/components/base-report-table/base-report-table.component';
import { CompactPatientDataModule } from '@/src/app/features/reports/components/compact-patient-data/compact-patient-data.module';
import { CompactPatientHeaderModule } from '@/src/app/features/reports/components/compact-patient-header/compact-patient-header.module';
import { PatientLinkComponentModule } from '@/src/app/features/reports/components/patient-link/patient-link.component';
import { ReportService } from '@/src/app/features/reports/components/report-filters/services/report.service';
import { TreatmentDateComponentModule } from '@/src/app/features/reports/components/treatment-date/treatment-date.component';
import { OrthodonticTreatmentImageComponentModule } from '@/src/app/features/reports/features/orthodontic-treatments-compact-report/components/orthodontic-treatments-compact-report-table/components/ortho-compact-report-image-pair/ortho-compact-report-image-pair.component';
import { OrthodonticTreatmentCompactReportRecord } from '@/src/app/features/reports/features/orthodontic-treatments-compact-report/orthodontic-treatments-compact-report.model';
import { OrthodonticInterventions } from '@/src/app/features/reports/features/orthodontic-treatments-report/orthodontic-treatments-report.model';
import { ImageThumbnailComponentModule } from '@/src/app/shared/components/image-thumbnail/image-thumbnail.component';
import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { ReadonlyCommonsModule } from '@/src/app/shared/modules/readonly-commons/readonly-commons.module';
import { IfEmptyPipeModule } from '@/src/app/shared/pipes/if-empty.pipe';
import { requiredPlaceholderImage } from '@/src/app/utils/media.utils';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyTableStateComponentModule } from '@shared/components/empty-table-state/empty-table-state.component';
import { TreatmentStatusIndicatorComponentModule } from '@shared/components/treatment-status-indicator/treatment-status-indicator.component';
import { AgeYearMonthPipeModule } from '@shared/modules/readonly-commons/pipes/age-year-month/age-year-month.pipe';
import { ReportFiltersModel } from '@src/app/features/reports/components/report-filters/models/report-filters.model';
import { ReportTableDataService } from 'src/app/features/reports/components/report-filters/services/report-table-data.service';
import { LocalNamePipeModule } from '@shared/pipes/local-name.pipe';

@Component({
  selector: 'stx-orthodontic-treatment-compact-report-table',
  templateUrl: './orthodontic-treatments-compact-report-table.component.html',
  styleUrls: ['./orthodontic-treatments-compact-report-table.component.scss']
})
export class OrthodonticTreatmentsCompactReportTableComponent
  extends BaseReportTableComponent<OrthodonticTreatmentCompactReportRecord>
  implements OnInit, OnDestroy
{
  readonly orthoTypeToTranslationKeyMap = orthoTypeToTranslationKeyMap;
  @Input() reportConfig: ReportFiltersModel;
  @Input() deIdentified = false;
  @Input() showPagination: boolean;

  displayedColumns: string[] = ['patient'];
  interventionTranslationsPathMap: Record<OrthodonticInterventions, string> = {
    [OrthodonticInterventions.PresurgicalOrthopedicsType_0]: 'presurgical.tap_lip',
    [OrthodonticInterventions.PresurgicalOrthopedicsType_1]: 'presurgical.tap_stent',
    [OrthodonticInterventions.PresurgicalOrthopedicsType_2]: 'presurgical.max_appliance',
    [OrthodonticInterventions.PresurgicalOrthopedicsType_3]: 'presurgical.nam',
    [OrthodonticInterventions.MixedDentitionType_0]: 'mixed_dentition.fixed_app',
    [OrthodonticInterventions.MixedDentitionType_1]: 'mixed_dentition.max_exp',
    [OrthodonticInterventions.MixedDentitionType_2]: 'mixed_dentition.face_mask',
    [OrthodonticInterventions.PermanentDentitionType_0]: 'permanent_dentition.full_fixed_app',
    [OrthodonticInterventions.PermanentDentitionType_1]: 'permanent_dentition.splints',
    [OrthodonticInterventions.DentitionTypeOther]: 'other'
  };
  readonly placeholderImage = requiredPlaceholderImage;
  readonly dateFormat = 'longDate';

  constructor(
    public changeDetector: ChangeDetectorRef,
    public reportTableDataService: ReportTableDataService,
    public reportService: ReportService,
    public route: ActivatedRoute
  ) {
    super('ORTHODONTIC_TREATMENTS_COMPACT', changeDetector, reportTableDataService, reportService, route);
  }

  get showQaOnlySection(): boolean {
    return !!this.reportConfig.get('qaOnly')?.qaOnly[0];
  }

  ngOnInit(): void {
    super.onInit();
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }

  getInterventionsDict(interventions: OrthodonticInterventions[]) {
    return interventions
      ?.map(intervention => {
        return { value: intervention, label: this.interventionTranslationsPathMap[intervention] } as DictionaryTranslation;
      })
      .filter(dictTranslation => !!dictTranslation);
  }

  getRankText(rankNumber: string): string {
    return rankNumber == '9' ? 'reports.filters.cannot_access_photo' : rankNumber;
  }
}

@NgModule({
  declarations: [OrthodonticTreatmentsCompactReportTableComponent],
  imports: [
    MatTableModule,
    MatSortModule,
    TranslateModule,
    CommonModule,
    MatIconModule,
    RouterModule,
    MatPaginatorModule,
    PatientLinkComponentModule,
    TreatmentStatusIndicatorComponentModule,
    TreatmentDateComponentModule,
    EmptyTableStateComponentModule,
    OrthodonticTreatmentImageComponentModule,
    MatDividerModule,
    FlexLayoutModule,
    AgeYearMonthPipeModule,
    CompactPatientDataModule,
    ReadonlyCommonsModule,
    IfEmptyPipeModule,
    GeneralCommonsModule,
    CompactPatientHeaderModule,
    ImageThumbnailComponentModule,
    CompactPatientHeaderModule,
    LocalNamePipeModule
  ],
  exports: [OrthodonticTreatmentsCompactReportTableComponent]
})
export class OrthodonticTreatmentCompactReportTableComponentModule {}
