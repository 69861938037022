import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { shareReplayBufferSize } from '@utils/http.utils';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BaseHttpService } from 'src/app/shared/services/http/base-http.service';
import { PermissionEnum } from '../../core/permissions/permission.enum';
import { AuthService } from '../authentication/services/auth.service';
import { DictionaryTranslation } from '../patient/models/patient.model';
import { OverrideQaStageParams, PractitionerQaReview, UpdateQaStageParams } from '../practitioner/models/qa-results.model';
import { CompleteReviewInformation } from './surgical/models/complete-review-information.model';
import { ReviewUpdate } from './surgical/models/review-update.model';

@Injectable({
  providedIn: 'root'
})
export class ReviewService extends BaseHttpService {
  private surgicalReviewDictionary$: Observable<Map<string, DictionaryTranslation[]>>;

  private surgical = 'surgical';
  private practitioner = 'practitioner';
  private qaStage = 'qa-stage';
  private override = 'override';

  constructor(httpClient: HttpClient, private authService: AuthService) {
    super(httpClient, { baseUrl: 'review' });
  }

  getNextSurgicalCase(): Observable<CompleteReviewInformation> {
    return this.httpClient.get<CompleteReviewInformation>(`${this.baseUrl}/${this.surgical}/get-next-case`);
  }

  getNextTrialSurgicalCase(): Observable<CompleteReviewInformation> {
    return this.httpClient.get<CompleteReviewInformation>(`${this.baseUrl}/${this.surgical}/get-next-trial-case`);
  }

  public submitSurgicalReview(reviewedTreatmentId: number, reviewUpdate: ReviewUpdate, trialCase: boolean): Observable<void> {
    if (!trialCase) {
      return this.httpClient.patch<void>(`${this.baseUrl}/${this.surgical}/update-review/${reviewedTreatmentId}`, reviewUpdate);
    } else {
      return this.httpClient.patch<void>(`${this.baseUrl}/${this.surgical}/update-trail-review/${reviewedTreatmentId}`, reviewUpdate);
    }
  }

  public getStaticDictionaries(): Observable<Map<string, DictionaryTranslation[]>> {
    if (!this.surgicalReviewDictionary$) {
      this.surgicalReviewDictionary$ = this.httpClient
        .get<Map<string, DictionaryTranslation[]>>(`${this.baseUrl}/static-dictionaries`)
        .pipe(shareReplay(shareReplayBufferSize))
        .pipe(map(res => new Map(Object.entries(res))));
    }
    return this.surgicalReviewDictionary$;
  }

  getPractitionerQaDetailsData(practitionerId: number): Observable<PractitionerQaReview> {
    return this.httpClient.get<PractitionerQaReview>(`${this.baseUrl}/${this.practitioner}/${practitionerId}`);
  }

  overrideQaReviewStage(stageId: number, params: OverrideQaStageParams): Observable<any> {
    if (this.authService.checkUserPermission(PermissionEnum.OVERRIDE_QA_STAGE)) {
      return this.httpClient.post<OverrideQaStageParams>(`${this.baseUrl}/${this.qaStage}/${stageId}/${this.override}`, params);
    }
    return of(null);
  }

  updateQaReviewStage(stageId: number, params: UpdateQaStageParams): Observable<any> {
    if (this.authService.checkUserPermission(PermissionEnum.UPDATE_QA_STAGE)) {
      return this.httpClient.patch<UpdateQaStageParams>(`${this.baseUrl}/${this.qaStage}/${stageId}`, params);
    }
    return of(null);
  }
}
