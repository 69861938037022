import { ActionIconCell, ExpandableRowGroup } from '@/src/app/shared/components/expandable-row-table/expandable-row.model';
import { TableColumn } from '@/src/app/shared/components/table/models/table-structure.model';
import { RadioGroupOptions } from '@/src/app/shared/models/form.model';
import { QaStageStatus, QaStageType, TreatmentGroup } from '../models/qa-results.enum';
import { QaReviewRow, QaReviewStage, QaReviewStageForDisplay, QaStageActions } from '../models/qa-results.model';

export function getStageActiveLabel(stageActive: boolean) {
  return stageActive ? 'yes' : 'no';
}

export function getQaReviewStageForDisplay(
  stage: QaReviewStage,
  actions: QaStageActions,
  canModifyStage: boolean
): QaReviewStageForDisplay {
  return !!stage
    ? {
        ...stage,
        trainingDate: getActionIconCellForTrainingDate(stage, actions, canModifyStage),
        treatmentGroup: surgeryOperationTypeTranslations.get(stage.treatmentGroup),
        type: getActionIconCellForStageCycle(stage, actions, canModifyStage),
        status: stageStatusTranslations.get(stage.status),
        active: getStageActiveLabel(stage.active)
      }
    : null;
}
export function getPractitionerQaReviewForDisplay(
  qaStages: QaReviewRow[],
  actions: QaStageActions,
  canModifyStage: boolean
): ExpandableRowGroup<QaReviewStageForDisplay>[] {
  return qaStages?.map(qs => ({
    row: getQaReviewStageForDisplay(qs.currentStage, actions, canModifyStage),
    expansionContent: {
      rows: qs.previousStages?.map(ps => getQaReviewStageForDisplay(ps, actions, canModifyStage)),
      expanded: false
    }
  }));
}

export function getActionIconCellForStageCycle(stage: QaReviewStage, actions: QaStageActions, canModifyStage: boolean): ActionIconCell {
  if (!stage) return null;

  const label = stageCycleTranslations.get(stage.type);

  const actionIconCell: ActionIconCell = { label: label, actionIcons: [] };

  if (!canModifyStage) {
    return actionIconCell;
  }

  if (stage.active && (stage.status === QaStageStatus.IN_PROGRESS || stage.status === QaStageStatus.TRAINING_SCHEDULED)) {
    if (stage.type !== QaStageType.FURTHER_RESEARCH) {
      actionIconCell.actionIcons.push({
        matIcon: 'cached',
        iconTooltip: 'practitioner_qa.override.tooltip',
        action: actions.override
      });
    }

    if (stage.type === QaStageType.TRAIN || stage.type === QaStageType.FURTHER_RESEARCH) {
      actionIconCell.actionIcons.push({
        matIcon: 'note_add',
        iconTooltip: 'practitioner_qa.assign_new.tooltip',
        action: actions.assignNewReviewStage
      });
    }
  }

  return actionIconCell;
}

export function getActionIconCellForTrainingDate(stage: QaReviewStage, actions: QaStageActions, canModifyStage: boolean): ActionIconCell {
  if (!stage) return null;

  const actionIconCell: ActionIconCell = { label: stage.trainingDate, actionIcons: [] };

  if (!canModifyStage) {
    return actionIconCell;
  }

  if (stage.active && stage.type === QaStageType.TRAIN) {
    actionIconCell.actionIcons.push({
      customIcon: 'today',
      iconTooltip: 'practitioner_qa.training_date.tooltip',
      action: actions.editTrainingDate
    });
  }

  return actionIconCell;
}

export const qaTableColumns: TableColumn[] = [
  { name: 'id', text: 'practitioner_qa.stage_id' },
  { name: 'treatmentGroup', text: 'practitioner_qa.operations' },
  { name: 'type', text: 'practitioner_qa.stage_cycle' },
  { name: 'active', text: 'practitioner_qa.stage_active' },
  { name: 'status', text: 'practitioner_qa.status' },
  { name: 'reviewStart', text: 'practitioner_qa.review_start' },
  { name: 'reviewEnd', text: 'practitioner_qa.review_end' },
  { name: 'casesPerStage', text: 'practitioner_qa.cases_per_stage' },
  { name: 'casesAssigned', text: 'practitioner_qa.cases_assigned' },
  { name: 'simpleCount', text: 'practitioner_qa.simple_count' },
  { name: 'simple', text: 'practitioner_qa.simple' },
  { name: 'complexCount', text: 'practitioner_qa.complex_count' },
  { name: 'complex', text: 'practitioner_qa.complex' },
  { name: 'failedPalatesOrAbg', text: 'practitioner_qa.failed_count' },
  { name: 'monitorUntilDate', text: 'practitioner_qa.monitor_until_date' },
  { name: 'monitorCount', text: 'practitioner_qa.monitor_count' },
  { name: 'trainingDate', text: 'practitioner_qa.training_date' }
];

export const stageCycleTranslations = new Map<QaStageType, string>()
  .set(QaStageType.NEW, 'practitioner_qa.stage_cycle.new')
  .set(QaStageType.PERIODIC, 'practitioner_qa.stage_cycle.periodic')
  .set(QaStageType.FURTHER_RESEARCH, 'practitioner_qa.stage_cycle.further_research')
  .set(QaStageType.MONITOR, 'practitioner_qa.stage_cycle.monitor')
  .set(QaStageType.TRAIN, 'practitioner_qa.stage_cycle.train')
  .set(QaStageType.MONITOR_POST_TRAINING, 'practitioner_qa.stage_cycle.monitor_post_training')
  .set(QaStageType.TERMINATION, 'practitioner_qa.stage_cycle.termination');

export const stageStatusTranslations = new Map<QaStageStatus, string>()
  .set(QaStageStatus.IN_PROGRESS, 'practitioner_qa.stage_cycle_state.in_progress')
  .set(QaStageStatus.FAIL, 'practitioner_qa.stage_cycle_state.fail')
  .set(QaStageStatus.PASS, 'practitioner_qa.stage_cycle_state.pass')
  .set(QaStageStatus.OVERRIDE, 'practitioner_qa.stage_cycle_state.override')
  .set(QaStageStatus.COMPLETED, 'practitioner_qa.stage_cycle_state.completed')
  .set(QaStageStatus.TRAINING_SCHEDULED, 'practitioner_qa.stage_cycle_state.training_scheduled')
  .set(QaStageStatus.TERMINATE, 'practitioner_qa.stage_cycle_state.terminate')
  .set(QaStageStatus.DISCONTINUED, 'practitioner_qa.stage_cycle_state.discontinued');

export const surgeryOperationTypeTranslations = new Map<TreatmentGroup, string>()
  .set(TreatmentGroup.SURGERY_LIP_NOSE_REVISION, 'surgical_qa.stage.lip_nose_revision')
  .set(TreatmentGroup.SURGERY_LIP_UNILATERAL, 'surgical_qa.stage.lip_unilateral')
  .set(TreatmentGroup.SURGERY_LIP_BILATERAL, 'surgical_qa.stage.lip_bilateral')
  .set(TreatmentGroup.SURGERY_ALVEOLAR_BONE_GRAFT, 'surgical_qa.stage.alveolar_bone_graft')
  .set(TreatmentGroup.SURGERY_PALATE, 'surgical_qa.stage.palate')
  .set(TreatmentGroup.SURGERY_PALATE_SECONDARY, 'surgical_qa.stage.palate_secondary');

export const newReviewStageOptions: RadioGroupOptions = [
  {
    label: stageCycleTranslations.get(QaStageType.MONITOR),
    value: QaStageType.MONITOR
  },
  {
    label: stageCycleTranslations.get(QaStageType.TRAIN),
    value: QaStageType.TRAIN
  },
  {
    label: stageCycleTranslations.get(QaStageType.MONITOR_POST_TRAINING),
    value: QaStageType.MONITOR_POST_TRAINING
  },
  {
    label: stageCycleTranslations.get(QaStageType.PERIODIC),
    value: QaStageType.PERIODIC
  }
];
