import { OrthoGeneralAssessmentInfoComponent } from '@/src/app/features/ortho/components/shared/ortho-general-assessment-info/ortho-general-assessment-info.component';
import { OrthoSharedGeneralInfoComponent } from '@/src/app/features/ortho/components/shared/ortho-shared-general-info/ortho-shared-general-info.component';
import { MixedDentitionAssessment } from '@/src/app/features/ortho/models/mixed-dentition-assessment.model';
import { MixedDentitionAssessmentService } from '@/src/app/features/ortho/services/mixed-dentition-assessment.service';
import { BaseOrthoFormComponent } from '@/src/app/features/ortho/shared/base-ortho-form.component';
import {
  dentitionAssessmentFacialImagesConfigs,
  dentitionAssessmentIntraOralImagesConfigs,
  dentitionAssessmentParentOrderNames,
  dentitionAssessmentXRayslImagesConfigs,
  fistulaFields,
  mixedDentitionApplianceFields,
  mixedDentitionApplianceTypes
} from '@/src/app/features/ortho/utils/dentition.utils';
import { OrthoFormType } from '@/src/app/features/ortho/utils/ortho-form-type';
import { mixedDentitionAssessmentAppRoute } from '@/src/app/features/ortho/utils/ortho-routes.consts';
import { OrthoStage } from '@/src/app/features/ortho/utils/ortho-stage';
import { PatientService } from '@/src/app/features/patient/patient.service';
import { SpinnerService } from '@/src/app/shared/components/spinner/service/spinner.service';
import { TreatmentType } from '@/src/app/shared/components/treatment/treatment.enum';
import { FormSection } from '@/src/app/shared/enums/form-section.enum';
import { FormType } from '@/src/app/shared/enums/form-type.enum';
import { ParentOrderName } from '@/src/app/shared/enums/parent-order-name.enum';
import { FormGuardService } from '@/src/app/shared/services/form-guard.service';
import { GlobalErrorHandlerService } from '@/src/app/shared/services/global-error-handler.service';
import { SnackBarService } from '@/src/app/shared/services/snack-bar.service';
import { WsHelperService } from '@/src/app/shared/services/ws-helper.service';
import { atLeastOneRequired, requiredIfValidator, selectionInvalidIf, StxValidators } from '@/src/app/shared/validation/validators';
import { FormMediaUtils } from '@/src/app/utils/form-media.utils';
import { FormMode, yesNoShort } from '@/src/app/utils/form.utils';
import { orthoRoute } from '@/src/app/utils/routing.utils';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

@Component({
  selector: 'stx-mixed-dentition-assessment',
  templateUrl: './mixed-dentition-assessment.component.html'
})
export class MixedDentitionAssessmentComponent extends BaseOrthoFormComponent<MixedDentitionAssessment> implements OnInit {
  readonly OrthoFormType = OrthoFormType;
  readonly FormSection = FormSection;
  readonly yesNo = yesNoShort;
  readonly mixedDentitionApplianceTypes = mixedDentitionApplianceTypes;
  readonly mixedDentitionTypesFields = mixedDentitionApplianceFields;
  readonly dentitionAssessmentFacialImagesConfigs = dentitionAssessmentFacialImagesConfigs;
  readonly dentitionAssessmentIntraOralImagesConfigs = dentitionAssessmentIntraOralImagesConfigs;
  readonly dentitionAssessmentXRayslImagesConfigs = dentitionAssessmentXRayslImagesConfigs;

  @ViewChild('generalAssessmentInfo') generalAssessmentInfo: OrthoGeneralAssessmentInfoComponent;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly orthoFormService: MixedDentitionAssessmentService,
    private readonly formGuardService: FormGuardService,
    spinnerService: SpinnerService,
    changeDetector: ChangeDetectorRef,
    globalErrorHandlerService: GlobalErrorHandlerService,
    router: Router,
    activatedRoute: ActivatedRoute,
    snackBarService: SnackBarService,
    elementRef: ElementRef,
    zone: NgZone,
    wsHelper: WsHelperService,
    patientService: PatientService
  ) {
    super(
      elementRef,
      zone,
      snackBarService,
      spinnerService,
      router,
      activatedRoute,
      changeDetector,
      globalErrorHandlerService,
      wsHelper,
      patientService,
      orthoFormService
    );
    this.formType = FormType.ORTHO_MD_ASSESSMENT;
    this.treatmentType = TreatmentType.ORTHO_MD_ASSESSMENT;
  }

  ngOnInit(): void {
    this.configureForm(this.createTreatmentFormGroup(), {
      controlNamesForFullValidation: [
        OrthoSharedGeneralInfoComponent.getOrthoCareProvidedDate(OrthoStage.ASSESSMENT),
        OrthoSharedGeneralInfoComponent.treatmentCenterIdControlName
      ]
    });
    if (!this.isReadonlyView) {
      this.getPatientTreatmentCenters();
    }
    this.fillInEditMode();
  }

  protected createTreatmentFormGroup(): UntypedFormGroup {
    return this.formBuilder.group(
      {
        [OrthoSharedGeneralInfoComponent.getOrthoCareProvidedDate(OrthoStage.ASSESSMENT)]: [null, StxValidators.required],
        [OrthoSharedGeneralInfoComponent.treatmentCenterIdControlName]: [null, StxValidators.required],
        [OrthoSharedGeneralInfoComponent.practitionerIdControlName]: [
          null,
          [
            StxValidators.required,
            selectionInvalidIf(
              () =>
                !!this.generalAssessmentInfo?.sharedGeneralInfo &&
                !!this.generalAssessmentInfo.sharedGeneralInfo.isSelectedPractitionerIdInvalid
            )
          ]
        ],
        infoGiven: [null, StxValidators.required],
        infoGivenMore: [],
        oralHealth: [null, StxValidators.required],
        oralHealthMore: [],
        fistula: [null, StxValidators.required],
        fistulaSoftPalate: [],
        fistulaHardPalate: [],
        fistulaAlveolarRidge: [],
        fistulaPreSurgery: [],
        fixedApp: [],
        maxExp: [],
        faceMask: [],
        otherDevice: [],
        [ParentOrderName.ORTHO_FRONTAL_PRE]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_LEFT_PRE]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_RIGHT_PRE]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_BASAL_PRE]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_FRONTAL_SMILE_PRE]: [],
        [ParentOrderName.ORTHO_INTRA_ORAL_FRONTAL_PRE]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_LEFT_OCCLUSION_PRE]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_RIGHT_OCCLUSION_PRE]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_MAXILLARY_ARCH_PRE]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_MANDIBULAR_ARCH_PRE]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_CEPHALOMETRIC_RADIOGRAPH_PRE]: [
          null,
          requiredIfValidator(() => this.treatmentFormGroup.get('faceMask').value === true)
        ],
        [ParentOrderName.ORTHO_PANORAMIC_RADIOGRAPH_PRE]: [null, StxValidators.required],
        [ParentOrderName.ORTHO_CLEFT_SITE_OCCLUSAL_OR_PERIAPICAL_RADIOGRAPH_PRE]: []
      },
      {
        validators: [
          atLeastOneRequired(mixedDentitionApplianceFields, 'orthodonticTypesFields'),
          atLeastOneRequired(fistulaFields, 'fistulaFields', 'fistula', 1)
        ]
      }
    );
  }
  private fillInEditMode() {
    if (this.formMode === FormMode.NEW) {
      return;
    }

    if (this.orthoFormModel) {
      this.setTreatmentData(this.orthoFormModel);
      return;
    }

    this.subSink.sink = this.activatedRoute.params.subscribe(params => {
      this.wsHelper
        .callWithSpinner(this.orthoFormService.get(params.id), { redirectOn404StatusCode: true })
        .subscribe(mixedDentitionAssessment => {
          this.formGuardService.redirectTreatmentFormToCorrectModeIfRequired(
            mixedDentitionAssessment,
            this.formMode,
            this.activatedRoute.snapshot
          );
          this.setTreatmentData(mixedDentitionAssessment);
        });
    });
  }
  protected getTreatmentDataToSave() {
    return {
      ...this.treatmentFormGroup.value,
      id: this.orthoFormModel ? this.orthoFormModel.id : null,
      patientId: this.patient.id,
      newFiles: FormMediaUtils.extractNewMediaFromFormGroup([this.treatmentFormGroup], dentitionAssessmentParentOrderNames)
    };
  }
  protected override beforeValidationSync(): void {
    this.generalAssessmentInfo.sharedGeneralInfo.checkPractitionerAndSurgeonValidity();
    super.beforeValidationSync();
  }
  protected callDelete(id: number): Observable<void> {
    return this.orthoFormService.delete(id);
  }

  protected callSave(data: MixedDentitionAssessment): Observable<MixedDentitionAssessment> {
    return this.orthoFormService.save(data);
  }

  protected callSubmit(data: MixedDentitionAssessment): Observable<MixedDentitionAssessment> {
    return this.orthoFormService.submit(data);
  }

  protected callUnlock(id: number): Observable<void> {
    return this.orthoFormService.unlock(id);
  }

  protected getEditRoute(treatmentId: TreatmentId): string {
    return `${orthoRoute}/${mixedDentitionAssessmentAppRoute}/edit/${treatmentId}`;
  }

  protected getPatientId(): number {
    return this.patient.id;
  }

  protected getTreatmentId(): TreatmentId {
    return this.orthoFormModel.id;
  }
  protected getViewRoute(treatmentId: TreatmentId): string {
    return `${orthoRoute}/${mixedDentitionAssessmentAppRoute}/${treatmentId}`;
  }

  protected setTreatmentData(data: MixedDentitionAssessment): void {
    this.orthoFormModel = data;
    this.treatmentFormGroup.patchValue(this.orthoFormModel);
    this.treatmentFormGroup.patchValue(FormMediaUtils.getMediaForFormGroup(this.orthoFormModel, dentitionAssessmentParentOrderNames));
    if (this.formMode !== FormMode.READONLY) {
      this.callFetchAdditionalDataForSetUp();
    }
  }
}
