import { AfterViewChecked, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { TableColumn } from '../table/models/table-structure.model';
import { ExpandableRowGroup, ExpansionContent } from './expandable-row.model';

@Component({
  selector: 'stx-expandable-row-table',
  templateUrl: './expandable-row-table.component.html',
  styleUrls: ['./expandable-row-table.component.scss']
})
export class ExpandableRowTableComponent implements OnInit, AfterViewChecked {
  @Input() data: ExpandableRowGroup[];
  @Input() columns: TableColumn[];

  dataSource: MatTableDataSource<ExpandableRowGroup | ExpansionContent>;
  displayedColumns: string[];
  expandedRowGroup: ExpandableRowGroup;

  @ViewChild(MatTable, { static: false })
  private table: MatTable<ExpandableRowGroup>;

  ngAfterViewChecked(): void {
    if (this.table) {
      this.table.updateStickyColumnStyles();
    }
  }

  get hidden() {
    return !this.data || !this.columns;
  }

  isExpansionContentRow = (i: number, row: Object) => {
    return row.hasOwnProperty('expanded');
  };

  isExpandableRowGroup = (i: number, row: Object) => {
    return !this.isExpansionContentRow(i, row);
  };

  ngOnInit(): void {
    if (!this.hidden) {
      this.updateDataSource();
      this.displayedColumns = this.columns.map(c => c.name);
    }
  }

  getFlexStyling(cellIndex: number) {
    // Hardcoded for qa-results.component
    switch (cellIndex) {
      case 4: {
        return '0 0 150px';
      }
      case 1: {
        return '0 0 260px';
      }
      default: {
        return '0 0 200px';
      }
    }
  }

  isExpandedRowGroup(rowGroup: ExpandableRowGroup) {
    return rowGroup && rowGroup.expansionContent?.expanded && rowGroup.expansionContent?.rows?.length > 0;
  }

  isExpandedContent(expansionContent: ExpansionContent) {
    return expansionContent && expansionContent.expanded && expansionContent.rows?.length > 0;
  }

  private updateDataSource() {
    const flatData = [];
    this.data.forEach(d => flatData.push(d, d.expansionContent));
    this.dataSource = new MatTableDataSource(flatData);
  }

  renderRows(updatedData: ExpandableRowGroup[]) {
    this.data = updatedData;
    this.updateDataSource();
    this.table.renderRows();
  }
}
