import { OrthoStageValidationData } from '@/src/app/features/ortho/models/ortho-stage-validation-data.model';
import { OrthoFormApiBase } from '@/src/app/features/ortho/shared/ortho-form-api-base';
import { BaseTreatment } from '@/src/app/features/surgical/models/base-treatment.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export abstract class OrthoTreatmentFormApi<OrthoTreatmentModel extends BaseTreatment> extends OrthoFormApiBase<OrthoTreatmentModel> {
  constructor(httpClient: HttpClient, apiRoute: string) {
    super(httpClient, apiRoute);
  }
  discontinue(treatment: OrthoTreatmentModel): Observable<OrthoTreatmentModel> {
    return this.httpClient.post<OrthoTreatmentModel>(`${this.baseUrl}/discontinue`, treatment);
  }
  override getValidationData(patientId: number, treatmentId?: number): Observable<OrthoStageValidationData> {
    const params = treatmentId ? new HttpParams().set('treatmentId', treatmentId.toString()) : null;
    return this.httpClient.get<OrthoStageValidationData>(`${this.baseUrl}/${patientId}/validation-data`, { params });
  }
}
