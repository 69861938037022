<stx-patient-forms-headers-container
  *ngIf="!expandable"
  [formType]="formType"
  cssClass="m-l-24 m-b-24"
></stx-patient-forms-headers-container>
<stx-treatment-status-bar
  *ngIf="!print && !expandable"
  [treatment]="speechTreatment"
  (unlockClicked)="unlock()"
  (deleteClicked)="delete()"
></stx-treatment-status-bar>

<ng-container *ngIf="isReadonlyView">
  <div class="page-section-container">
    <stx-speech-treatment-readonly *ngIf="isReadonlyView" [treatment]="speechTreatment"></stx-speech-treatment-readonly>
  </div>
</ng-container>

<form *ngIf="!isReadonlyView && staticDictionaries && tcList && patient" class="m-t-24" [formGroup]="treatmentFormGroup">
  <stx-form-panel>
    <div class="m-t-2 m-b-24">
      <p class="form-element-label m-b-2">{{ 'speech_treatment.first_form_heading' | translate }}</p>
      <p class="form-element-label">{{ 'speech_treatment.second_form_heading' | translate }}</p>
    </div>

    <stx-speech-basic-info
      [isTreatment]="true"
      [tcList]="tcList"
      [formGroup]="treatmentFormGroup"
      [dateOfBirth]="patient.dateOfBirth"
      [formMode]="formMode"
      [print]="print"
      [expandable]="expandable"
    ></stx-speech-basic-info>
    <hr class="form-section-separator" />
    <div class="form-section">
      <div class="form-columns-1-1" [ngClass]="{ 'flex-row-important': print }">
        <div>
          <div class="form-row">
            <p class="form-subsection-heading">
              {{ 'speech_instructions.treatment.period' | translate
              }}<stx-tooltip *ngIf="!print" content="speech_instructions.treatment.period_comment"></stx-tooltip>
            </p>
          </div>
          <div class="form-row" [ngClass]="{ 'flex-column-container print-view-div-max-size': print }">
            <stx-date
              [label]="'start_date'"
              [controlName]="'treatmentStart'"
              [formGroup]="treatmentFormGroup"
              [minDate]="afterDateOfBirth"
              [maxDateToday]="true"
              [print]="print"
              [formMode]="formMode"
            ></stx-date>
          </div>
          <div class="form-row" [ngClass]="{ 'flex-column-container print-view-div-max-size': print }">
            <stx-date
              [label]="'end_date'"
              [controlName]="'treatmentEnd'"
              [formGroup]="treatmentFormGroup"
              [minDate]="treatmentEndMinDate"
              [maxDate]="treatmentEndMaxDate"
              [print]="print"
              [formMode]="formMode"
            ></stx-date>
          </div>
          <div class="form-row" [ngClass]="{ 'print-view-div-max-size': print }">
            <stx-radio-group
              [label]="'speech_treatment.treatment_discontinued'"
              [controlName]="'isAbandoned'"
              [options]="yesNo"
              [formGroup]="treatmentFormGroup"
              [formMode]="formMode"
              [print]="print"
              [layoutDirection]="'row'"
            ></stx-radio-group>
          </div>
          <ng-container *ngIf="!!this.formGroup?.get('isAbandoned')?.value || print">
            <div class="form-row" [ngClass]="{ 'print-view-div-max-size': print }">
              <stx-textarea
                [label]="'ortho.abandon_reason_more'"
                [controlName]="'abandonReasonMore'"
                [formGroup]="treatmentFormGroup"
                [print]="print"
                [formMode]="formMode"
              ></stx-textarea>
            </div>
          </ng-container>
        </div>
        <div>
          <div class="form-row">
            <p class="form-subsection-heading">
              {{ 'speech_treatment.service_count' | translate }}
            </p>
          </div>
          <div class="form-row" [ngClass]="{ 'print-view-div-max-size': print }">
            <stx-input
              [label]="'speech_treatment.service_count_label'"
              [formMode]="formMode"
              [formGroup]="treatmentFormGroup"
              [controlName]="'serviceCount'"
              [print]="print"
              [type]="'number'"
            ></stx-input>
          </div>
          <div class="form-row" [ngClass]="{ 'print-view-div-max-size': print }">
            <stx-input
              [label]="'treatment.telehealth_session_number'"
              [formMode]="formMode"
              [formGroup]="treatmentFormGroup"
              [controlName]="'telehealthCount'"
              [print]="print"
              [type]="'number'"
            ></stx-input>
          </div>
        </div>
        <div>
          <p class="form-subsection-heading">
            {{ 'speech_treatment.treatment_model' | translate }}
            <stx-tooltip
              *ngIf="!print"
              [content]="{
                template: '{0}\n{1}\n{2}',
                translationKeys: [
                  'speech_instructions.treatment.frequency_comment_1',
                  'speech_instructions.treatment.frequency_comment_2',
                  'speech_instructions.treatment.frequency_comment_3'
                ]
              }"
            ></stx-tooltip>
          </p>
          <div class="form-row">
            <stx-checkbox-group
              [formGroup]="treatmentFormGroup"
              [formMode]="formMode"
              [options]="treatmentModelOptions"
              [layoutDirection]="'column'"
              [print]="print"
              [formGroupErrorNames]="['treatmentModelFields']"
            ></stx-checkbox-group>
          </div>
          <p class="form-subsection-heading p-t-30">
            {{ 'speech_treatment.treatment_frequency' | translate }}
          </p>
          <div class="form-row">
            <stx-checkbox-group
              [formGroup]="treatmentFormGroup"
              [formMode]="formMode"
              [options]="treatmentFrequencyOptions"
              [layoutDirection]="'column'"
              [print]="print"
              [formGroupErrorNames]="['frequencyFields']"
            ></stx-checkbox-group>
          </div>
          <div class="form-row">
            <stx-input
              [label]="'speech_treatment.service_other'"
              [controlName]="'serviceOther'"
              [formGroup]="treatmentFormGroup"
              [formMode]="formMode"
            >
            </stx-input>
          </div>
        </div>
      </div>
    </div>
  </stx-form-panel>

  <ng-container *ngFor="let goalNumber of [1, 2, 3, 4]">
    <div [ngClass]="{ 'p-t-30 page-break-inside-avoid': print }">
      <ng-container *ngIf="goalNumber === 2"> </ng-container>
      <stx-treatment-goal
        *ngIf="staticDictionaries"
        [goalNumber]="goalNumber"
        [formMode]="formMode"
        [formGroup]="treatmentFormGroup"
        [staticDictionaries]="staticDictionaries"
        [print]="print"
        [minGoalStartedDate]="afterDateOfBirth"
      ></stx-treatment-goal>
    </div>
  </ng-container>
  <div *ngIf="!print" class="page-break"></div>

  <stx-form-panel [ngClass]="{ 'p-t-30': print }">
    <stx-treatment-outcomes
      *ngIf="staticDictionaries"
      [formMode]="formMode"
      [formGroup]="treatmentFormGroup"
      [staticDictionaries]="staticDictionaries"
      [speechTreatment]="speechTreatment"
      [print]="print"
    >
    </stx-treatment-outcomes>
  </stx-form-panel>

  <stx-action-buttons
    *ngIf="!print"
    [asyncSecondaryAction]="onSaveButtonClicked()"
    [asyncPrimaryAction]="treatmentFormGroup.get('isAbandoned').value === true ? onDiscontinue() : onSubmitButtonClicked()"
  ></stx-action-buttons>
</form>
