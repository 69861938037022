import { DateValidatorsService } from '@/src/app/shared/validation/date-validators/dates-validator.service';
import { StxValidators } from '@/src/app/shared/validation/validators';
import { getToday } from '@/src/app/utils/date.utils';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import {
  AgeFilterModel,
  CommonReportDictionaryItem,
  NutritionStatusesChangePayload,
  OrthoStatusesChangePayload,
  ReportConfig,
  ReportDictionaryTree,
  ReportDictionaryTreeItem,
  ReportsDictionariesResponse,
  SectionedCommonReportDictionaryItem
} from '../models/report-filters.model';
import { ReportFiltersHelperService } from './report-filters-helper.service';
import { ReportPeriodsService } from './report-periods.service';

export interface AddCheckboxGroupOptions {
  rootGroup: UntypedFormGroup;
  groupName: string;
  dictionaryList: CommonReportDictionaryItem[];
  defaultValue?: boolean;
}

export interface AddGroupTreeOptions {
  rootGroup: UntypedFormGroup;
  groupName: string;
  dictionaryTree: ReportDictionaryTree;
  rootOptionValue?: string | number;
  childOptionValue?: any;
}

export interface AddCheckboxGroupTreeOptions {
  rootGroup: UntypedFormGroup;
  groupName: string;
  dictionaryTree: ReportDictionaryTreeItem[];
  preselectedRootOptions: Set<string>;
}

@Injectable({
  providedIn: 'root'
})
export class ReportFiltersFormGeneratorService {
  private static readonly speechAssessmentStatuses = {
    submitted: '0',
    inProgress: '9'
  };

  private static readonly speechTreatmentStatuses = {
    submitted: '0',
    discontinued: '8',
    inProgress: '9'
  };

  static readonly orthoStatusesFilterNames: Array<keyof OrthoStatusesChangePayload> = [
    'mdAssessmentStates',
    'mdTreatmentStates',
    'mdMidAssessmentStates',
    'pdAssessmentStates',
    'pdTreatmentStates',
    'pdMidAssessmentStates',
    'psioAssessmentStates',
    'psioTreatmentStates'
  ];

  static readonly nutritionFilterNames: Array<keyof NutritionStatusesChangePayload> = [
    'nutritionAssessmentStates',
    'nutritionFollowUpStates'
  ];

  private static readonly nutritionStatsAgeGroups = {
    infant: 'INFANT',
    toddler: 'TODDLER',
    adolescent: 'ADOLESCENT',
    adult: 'ADULT'
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dateValidatorsService: DateValidatorsService,
    private reportFiltersHelperService: ReportFiltersHelperService,
    private reportPeriodsService: ReportPeriodsService
  ) {}

  generateFormGroup(config: ReportConfig, dictionaries: ReportsDictionariesResponse): UntypedFormGroup {
    const { filters } = config;
    const reportFiltersFormGroup: UntypedFormGroup = this.formBuilder.group({});

    if (filters.age) {
      this.addAgeFormGroup(reportFiltersFormGroup);
    }

    if (filters.date) {
      const areDatesRequired = ['PARTNER_SURGERY_COUNTS', 'PARTNER_SURGERY_COUNTS_FOUNDATION_CONNECT', 'MONTH_END'].includes(config.type);

      this.addDateGroup(reportFiltersFormGroup, areDatesRequired, config.additionalValidators?.maxDatesDifferenceInYears);
    }

    if (filters.organizationsTree) {
      this.addOrganizationsTreeToFormGroup(reportFiltersFormGroup);
    }

    if (filters.geography) {
      this.addGeographyTreeFormGroup(reportFiltersFormGroup);
    }

    if (filters.gender) {
      this.appendCommonFormGroup(reportFiltersFormGroup, 'gender');
    }

    if (filters.includePhotos) {
      this.appendCommonBooleanControl(reportFiltersFormGroup, 'includePhotos');
    }

    if (filters.latestCases) {
      this.appendCommonControl(reportFiltersFormGroup, 'latestCases');
    }

    if (filters.periodType) {
      this.addPeriodGroup(reportFiltersFormGroup, config);
    }

    if (filters.referringOrganization) {
      this.appendCommonControl(reportFiltersFormGroup, 'referringOrganization');
    }

    if (filters.statsType) {
      this.appendCommonControl(reportFiltersFormGroup, 'statsType');
    }

    if (filters.status) {
      this.appendCommonControl(reportFiltersFormGroup, 'status');
    }

    if (filters.reportedComplications) {
      this.appendCommonControl(reportFiltersFormGroup, 'reportedComplications');
    }

    if (filters.surgicalIntervention) {
      this.appendCommonFormGroup(reportFiltersFormGroup, 'surgicalIntervention');
    }

    if (filters.deIdentification) {
      this.appendCommonBooleanControl(reportFiltersFormGroup, 'deIdentification');
    }

    if (filters.qaOnly) {
      this.appendCommonBooleanControl(reportFiltersFormGroup, 'qaOnly');
    }

    if (filters.qualityAssurance) {
      this.addQualityAssuranceFormGroup(reportFiltersFormGroup);
    }

    if (filters.recordDate) {
      this.addRecordDateFormGroup(reportFiltersFormGroup);
    }

    if (filters.surgeons) {
      this.appendCommonControl(reportFiltersFormGroup, 'surgeons');
    }

    if (filters.practitioners) {
      this.appendCommonControl(reportFiltersFormGroup, 'practitioners');
    }

    if (filters.orthodontists) {
      this.appendCommonControl(reportFiltersFormGroup, 'orthodontists');
    }

    if (filters.reviewers) {
      this.appendCommonControl(reportFiltersFormGroup, 'reviewers');
    }

    if (filters.repairTypes) {
      this.addRepairTypesControl(reportFiltersFormGroup);
    }

    if (filters.orthoTypes) {
      this.addOrthodonticStatesControl(reportFiltersFormGroup);
      this.appendCommonBooleanControl(reportFiltersFormGroup, 'allSelectedTreatmentTypes');
    }

    if (filters.groupByScope) {
      this.appendCommonControl(reportFiltersFormGroup, 'groupByScope');
    }

    if (filters.treatmentNotCompliant) {
      this.appendCommonControl(reportFiltersFormGroup, 'treatmentNotCompliant');
    }

    if (filters.orthoCasesTypes) {
      this.addCheckboxGroup({
        dictionaryList: ReportFiltersFormGeneratorService.flattenOrthoStatsFilters(
          dictionaries.orthoPsioCategories,
          dictionaries.orthoMdCategories,
          dictionaries.orthoPdCategories
        ),
        groupName: 'orthoCasesTypes',
        rootGroup: reportFiltersFormGroup
      });
    }

    if (filters.speechAssessmentStatuses) {
      this.addCheckboxTreeGroup({
        rootGroup: reportFiltersFormGroup,
        dictionaryTree: dictionaries.speechAssessmentStatuses,
        groupName: 'speechAssessmentStatuses',
        preselectedRootOptions: new Set([ReportFiltersFormGeneratorService.speechAssessmentStatuses.submitted])
      });
    }

    if (filters.speechTreatmentStatuses) {
      this.addCheckboxTreeGroup({
        rootGroup: reportFiltersFormGroup,
        dictionaryTree: dictionaries.speechTreatmentStatuses,
        groupName: 'speechTreatmentStatuses',
        preselectedRootOptions: new Set([
          ReportFiltersFormGeneratorService.speechTreatmentStatuses.submitted,
          ReportFiltersFormGeneratorService.speechTreatmentStatuses.discontinued
        ])
      });
    }

    if (filters.mohGrades) {
      this.addCheckboxGroup({
        rootGroup: reportFiltersFormGroup,
        dictionaryList: dictionaries.mohGrades,
        groupName: 'mohGrades',
        defaultValue: false
      });
    }

    if (filters.exclusions) {
      this.appendCommonFormGroup(reportFiltersFormGroup, 'exclusions');
    }

    if (filters.treatmentStatus) {
      this.appendCommonFormGroup(reportFiltersFormGroup, 'treatmentStatus');
    }

    if (filters.rejectedRecords) {
      this.appendCommonBooleanControl(reportFiltersFormGroup, 'rejectedRecords');
    }

    if (filters.photoTypes) {
      this.addCheckboxGroup({
        rootGroup: reportFiltersFormGroup,
        dictionaryList: dictionaries.photoTypes,
        groupName: 'photoTypes'
      });
    }

    if (filters.qaStages) {
      this.addCheckboxGroup({
        rootGroup: reportFiltersFormGroup,
        dictionaryList: dictionaries.qaStages,
        groupName: 'qaStages'
      });
    }

    if (filters.speechAssessmentStates) {
      this.appendCommonFormGroup(reportFiltersFormGroup, 'speechAssessmentStates');
    }

    if (filters.speechTreatmentStates) {
      this.appendCommonFormGroup(reportFiltersFormGroup, 'speechTreatmentStates');
    }

    if (filters.speechAssessmentRecommendations) {
      this.appendCommonFormGroup(reportFiltersFormGroup, 'speechAssessmentRecommendations');
    }

    if (filters.orthoRecordDates) {
      this.addGroupTree({
        rootGroup: reportFiltersFormGroup,
        dictionaryTree: dictionaries.orthoRecordDates,
        groupName: 'orthoRecordDates',
        rootOptionValue: dictionaries.orthoRecordDates?.careProvided?.value
      });
      this.addDateGroup(reportFiltersFormGroup);
    }

    if (filters.ageGroups) {
      this.addCheckboxGroup({
        rootGroup: reportFiltersFormGroup,
        dictionaryList: dictionaries.ageGroups,
        groupName: 'ageGroups',
        defaultValue: false
      });
    }

    if (filters.nutritionTypes) {
      this.addNutritionStatesControl(reportFiltersFormGroup);
      this.appendCommonBooleanControl(reportFiltersFormGroup, 'allSelectedTreatmentTypes');
    }

    if (filters.nutritionists) {
      this.appendCommonControl(reportFiltersFormGroup, 'nutritionists');
    }

    if (filters.orthoQaGrading) {
      this.appendCommonBooleanControl(reportFiltersFormGroup, 'goodCaseExample');
      this.appendCommonBooleanControl(reportFiltersFormGroup, 'needsImmediateAttention');
      this.appendCommonBooleanControl(reportFiltersFormGroup, 'poorPatientSelection');

      this.addCheckboxGroup({
        rootGroup: reportFiltersFormGroup,
        dictionaryList: dictionaries.orthoPreGrading,
        groupName: 'preGrading',
        defaultValue: false
      });

      this.addCheckboxGroup({
        rootGroup: reportFiltersFormGroup,
        dictionaryList: dictionaries.orthoPostGrading,
        groupName: 'postGrading',
        defaultValue: false
      });
    }

    if (filters.ageGroupsWithTreatmentStatuses) {
      this.addCheckboxTreeGroup({
        rootGroup: reportFiltersFormGroup,
        dictionaryTree: dictionaries.ageGroupsWithTreatmentStatuses,
        groupName: 'ageGroupsWithTreatmentStatuses',
        preselectedRootOptions: new Set([
          ReportFiltersFormGeneratorService.nutritionStatsAgeGroups.infant,
          ReportFiltersFormGeneratorService.nutritionStatsAgeGroups.toddler,
          ReportFiltersFormGeneratorService.nutritionStatsAgeGroups.adolescent,
          ReportFiltersFormGeneratorService.nutritionStatsAgeGroups.adult
        ])
      });
    }

    return reportFiltersFormGroup;
  }

  private addAgeFormGroup(formGroup: UntypedFormGroup) {
    const initialValue: AgeFilterModel = { from: 0, to: 0 };

    formGroup.addControl('age', new UntypedFormControl(initialValue, []));
  }

  private addOrganizationsTreeToFormGroup(formGroup: UntypedFormGroup) {
    const organizationsTreeFormGroup: UntypedFormGroup = this.formBuilder.group({
      partners: [[]],
      treatmentCenters: [[]]
    });

    formGroup.addControl('organizationsTree', organizationsTreeFormGroup);
  }

  private addGeographyTreeFormGroup(formGroup: UntypedFormGroup) {
    const organizationsTreeFormGroup: UntypedFormGroup = this.formBuilder.group({
      crms: [[]],
      crmRegions: [[]],
      countries: [[]],
      zoneCountries: [[]],
      provinces: [[]],
      zoneProvinces: [[]]
    });

    formGroup.addControl('geographyTree', organizationsTreeFormGroup);
  }

  private addRepairTypesControl(formGroup: UntypedFormGroup) {
    const repairTypesFormGroup: UntypedFormGroup = this.formBuilder.group(
      {
        primaryLipNoseUnilateral: this.formBuilder.group({}),
        primaryLipNoseBilateral: this.formBuilder.group({}),
        lipNoseRevision: this.formBuilder.group({}),
        primaryCleftPalate: this.formBuilder.group({}),
        fistulaRepair: this.formBuilder.group({}),
        secondaryCleftPalate: this.formBuilder.group({}),
        other: this.formBuilder.group({}),
        velopharyngealDysfunction: this.formBuilder.group({}),
        alveolarBoneGraft: this.formBuilder.group({})
      },
      { validators: [], updateOn: 'change' }
    );

    formGroup.addControl('repairTypes', repairTypesFormGroup);
  }

  private addOrthodonticStatesControl(formGroup: UntypedFormGroup) {
    this.addStatesControlForFormGroup(formGroup, ReportFiltersFormGeneratorService.orthoStatusesFilterNames);
  }

  private addNutritionStatesControl(formGroup: UntypedFormGroup) {
    this.addStatesControlForFormGroup(formGroup, ReportFiltersFormGeneratorService.nutritionFilterNames);
  }

  private addStatesControlForFormGroup(formGroup: UntypedFormGroup, filterNames: Array<keyof OrthoStatusesChangePayload>) {
    filterNames.forEach((controlName: string) =>
      formGroup.addControl(
        controlName,
        new UntypedFormControl([], {
          validators: [],
          updateOn: 'change'
        })
      )
    );
  }

  private appendCommonBooleanControl(formGroup: UntypedFormGroup, controlName: string) {
    formGroup.addControl(controlName, new UntypedFormControl(false, []));
  }

  private appendCommonControl(formGroup: UntypedFormGroup, controlName: string) {
    formGroup.addControl(controlName, new UntypedFormControl('', []));
  }

  private appendCommonFormGroup(formGroup: UntypedFormGroup, controlName: string) {
    const commonFormGroup: UntypedFormGroup = this.formBuilder.group(
      {},
      {
        validators: [],
        updateOn: 'change'
      }
    );

    formGroup.addControl(controlName, commonFormGroup);
  }

  private addQualityAssuranceFormGroup(formGroup: UntypedFormGroup) {
    const qualityAssuranceFormGroup: UntypedFormGroup = this.formBuilder.group(
      {
        surgicalIntervention: this.formBuilder.group({}, { validators: [], updateOn: 'change' }),
        surgeryStatus: this.formBuilder.group({}, { validators: [], updateOn: 'change' }),
        preRank: this.formBuilder.group({}, { validators: [], updateOn: 'change' }),
        postRank: this.formBuilder.group({}, { validators: [], updateOn: 'change' })
      },
      {
        validators: [],
        updateOn: 'change'
      }
    );

    formGroup.addControl('qualityAssurance', qualityAssuranceFormGroup);
  }

  private addRecordDateFormGroup(formGroup: UntypedFormGroup) {
    const groupName = 'recordDate';

    const group = this.formBuilder.group(
      {
        recordType: ['', []],
        datePreset: ['', []]
      },
      { updateOn: 'change' }
    );

    // ensured date filter form groups is added, as record date filter internally uses date filter
    this.addDateGroup(formGroup);
    formGroup.addControl(groupName, group);
  }

  private addDateGroup(formGroup: UntypedFormGroup, datesRequired?: boolean, maxRangeInYears?: number) {
    const groupName = 'dates';

    const today = getToday();
    const dateFromValidators = [
      this.dateValidatorsService.notGreaterThan({
        compareToDate: today
      })
    ];
    const dateToValidators = [
      this.dateValidatorsService.notGreaterThan({
        compareToDate: today
      })
    ];

    if (datesRequired) {
      dateFromValidators.unshift(StxValidators.required);
      dateToValidators.unshift(StxValidators.required);
    }

    const additionalValidators: Array<ValidatorFn | null> = [];
    if (!!maxRangeInYears) {
      additionalValidators.push(StxValidators.maxDatesDifference('dateFrom', 'dateTo', maxRangeInYears));
    }

    const group = this.formBuilder.group(
      {
        dateFrom: ['', dateFromValidators],
        dateTo: ['', dateToValidators]
      },
      {
        validators: additionalValidators.length > 0 ? additionalValidators : null
      }
    );
    formGroup.addControl(groupName, group);
  }

  private addPeriodGroup(formGroup: UntypedFormGroup, config: ReportConfig) {
    const group = this.formBuilder.group({
      periodType: ['', []]
    });

    if (config.filters.periodPresets) {
      const calendarYears = this.reportPeriodsService.getCalendarYears();
      const fiscalYears = this.reportPeriodsService.getFiscalYears();
      const quarters = this.reportPeriodsService.getQuarters();
      const months = this.reportPeriodsService.getMonths();
      const periodPresets = this.formBuilder.group({
        calendarYears: this.formBuilder.group({}),
        fiscalYears: this.formBuilder.group({}),
        quarters: this.formBuilder.group({}),
        months: this.formBuilder.group({})
      });

      this.reportPeriodsService.addToFormGroup(periodPresets.get('calendarYears') as UntypedFormGroup, calendarYears);
      this.reportPeriodsService.addToFormGroup(periodPresets.get('fiscalYears') as UntypedFormGroup, fiscalYears);
      this.reportPeriodsService.addToFormGroup(periodPresets.get('quarters') as UntypedFormGroup, quarters);
      this.reportPeriodsService.addToFormGroup(periodPresets.get('months') as UntypedFormGroup, months);
      group.addControl('periodPresets', periodPresets);
    }

    formGroup.addControl('period', group);
  }

  private addCheckboxGroup(options: AddCheckboxGroupOptions) {
    const group = this.formBuilder.group({});
    const checkboxes = this.reportFiltersHelperService.getCheckboxGroupOptionsFromDictionary(options.dictionaryList);

    this.reportFiltersHelperService.addCheckboxGroupOptionsToFormGroup(group, checkboxes);
    if (options.hasOwnProperty('defaultValue')) {
      this.reportFiltersHelperService.setAllCheckboxesValue(group, options.defaultValue);
    }
    options.rootGroup.addControl(options.groupName, group);
  }

  addCheckboxTreeGroup(options: AddCheckboxGroupTreeOptions) {
    const rootOptions = this.formBuilder.group({});
    const childrenOptions = this.formBuilder.group({});

    options.dictionaryTree.forEach(rootOption => {
      const initialValue = options.preselectedRootOptions.has(rootOption.value);

      rootOptions.addControl(rootOption.value, new UntypedFormControl(initialValue, { updateOn: 'change' }));

      (rootOption.subSections || []).forEach(subSection => {
        const subSectionGroup = this.formBuilder.group({});
        const subSectionOptions = this.reportFiltersHelperService.getCheckboxGroupOptionsFromDictionary(subSection.values);

        this.reportFiltersHelperService.addCheckboxGroupOptionsToFormGroup(subSectionGroup, subSectionOptions);

        childrenOptions.addControl(subSection.name, subSectionGroup);
      });
    });

    options.rootGroup.addControl(
      options.groupName,
      this.formBuilder.group({
        rootOptions,
        childrenOptions
      })
    );
  }

  private addGroupTree(options: AddGroupTreeOptions) {
    const childrenOptions = this.formBuilder.group({});
    Object.values(options.dictionaryTree).forEach(rootOption => {
      if (rootOption.subSections) {
        const childControlName = rootOption.value.toString();
        if (options.childOptionValue && childControlName === options.rootOptionValue) {
          childrenOptions.addControl(childControlName, new UntypedFormControl(options.childOptionValue));
        } else {
          childrenOptions.addControl(childControlName, new UntypedFormControl(null));
        }
      }
    });

    const group = this.formBuilder.group({
      rootOption: new UntypedFormControl(options.rootOptionValue || '', StxValidators.required),
      childrenOptions
    });

    options.rootGroup.addControl(options.groupName, group);
  }

  private static flattenOrthoStatsFilters(...filters: Array<SectionedCommonReportDictionaryItem>): CommonReportDictionaryItem[] {
    let allCategories: CommonReportDictionaryItem[] = [];
    filters.forEach(filterDictionary => {
      for (const orthoType in filterDictionary) {
        allCategories = [...allCategories, ...filterDictionary[orthoType]];
      }
    });
    return allCategories;
  }
}
