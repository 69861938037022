<stx-generic-page>
  <h1>{{ 'profiles' | translate }}</h1>
  <ng-container *ngFor="let profile of profiles">
    <stx-action-buttons
      [asyncPrimaryAction]="selectProfile(profile.id)"
      [showSecondary]="false"
      [primaryText]="('role' | translate) + ': ' + profile.role.name + ' & ' + ('profile' | translate) + ': ' + profile.name"
    ></stx-action-buttons
  ></ng-container>
</stx-generic-page>
