import { ActivatedRouteSnapshot } from '@angular/router';

export const maintenancePageRoute = 'maintenance';
export const loginRoute = 'login';
export const resetPasswordRoute = 'reset-password';
export const usersRoute = 'users';
export const newRoute = 'new';
export const editRoute = 'edit';
export const settingsRoute = 'settings';
export const selectProfileRoute = 'select-profile';
export const partnersRoute = 'partners';
export const reportsMainRoute = 'reports';
export const paperRecordRoute = 'paper-records';
export const surgicalTreatmentsCompactRoute = 'surgical-treatments-compact';
export const orthodonticTreatmentsReportRoute = 'orthodontic-treatments';
export const orthodonticTreatmentsCompactReportRoute = 'orthodontic-treatments-compact';
export const qaEvaluatorReviewsReportRoute = 'qa-evaluator-reviews';
export const qaEvaluatorReviewsOrthoReportRoute = `${qaEvaluatorReviewsReportRoute}/orthodontic`;
export const qaEvaluatorReviewsSurgicalReportRoute = `${qaEvaluatorReviewsReportRoute}/surgical`;
export const partnerSurgeryCountsReportRoute = 'partner-surgery-counts';
export const yearToDateReportRoute = 'year-to-date';
export const surgicalTreatmentsRoute = 'surgical-treatments';
export const surgicalFollowUpVisitsRoute = 'surgical-follow-up-visits';
export const orthognathicTreatmentsRoute = 'orthognathic-treatments';
export const qaReviewerReliability = 'qa-reviewer-reliability';
export const qaOrthoCasesReportRoute = 'qa-ortho-cases';
export const speechTreatmentsReportRoute = 'speech-treatments';
export const speechTreatmentReportRoute = 'speech-treatment';
export const speechAssessmentReportRoute = 'speech-assessment';
export const paperRecordsReportRoute = 'paper-records';
export const speechStatsReportRoute = 'speech-stats';
export const surgeryStatsReportRoute = 'surgery-stats';
export const orthodonticStatsReportRoute = 'orthodontic-stats';
export const nutritionStatsReportRoute = 'nutrition-stats';
export const patientsRoute = 'patients';
export const errorResourceNotFound = 'resource-not-found';
export function patientDetailsRoute(patientId: number) {
  return [patientRoute, patientId];
}
export function patientDetailsPath(patientId: number | string) {
  return `/${patientRoute}/${patientId}`;
}
export function patientHistoryPath(patientId: number | string) {
  return `/${patientRoute}/${patientId}/print`;
}
export const patientRoute = 'patient';
export const reviewRoute = 'review';
export const photoReportRoute = 'photo-report';
export const geoRoute = 'geography';
export const treatmentCentersRoute = 'treatment-centers';
export const surgicalRoute = 'surgery';
export const followUp = 'follow-up';
export const intervention = 'intervention';
export const newFollowUp = `${followUp}/${newRoute}`;
export const surgeryFollowUp = `${followUp}/:id`;
export const editSurgeryFollowUp = `${followUp}/${editRoute}/:id`;
export const surgeryFollowUpFull = `${surgicalRoute}/${followUp}`;
export const newIntervention = `${intervention}/${newRoute}`;
export const editIntervention = `${intervention}/${editRoute}/:id`;
export const readOnlyIntervention = `${intervention}/:id`;
export const borescopeRoute = 'borescope';
export const newBorescope = `${borescopeRoute}/${newRoute}`;
export const editBorescope = `${borescopeRoute}/${editRoute}/:id`;
export const readonlyBorescope = `${borescopeRoute}/:id`;
export const orthognathicRoute = 'orthognathic';
export const monthEndReportRoute = 'month-end';
export const monthEndSurgicalReportRoute = `${monthEndReportRoute}/${surgicalRoute}`;
export const monthEndOrthognathicReportRoute = `${monthEndReportRoute}/${orthognathicRoute}`;

export const speechRoute = 'speech';
export const speechAssessmentRoute = 'assessment';
export const speechTreatmentRoute = 'treatment';
export const instructionsRoute = 'instructions';
export const speechInstructionsPageRoute = `/${speechRoute}/${instructionsRoute}`;

export const orthoRoute = 'ortho';
export const orthoReviewRoute = `${reviewRoute}/${orthoRoute}`;

export const nutritionPilotRoute = 'nutrition-pilot';

export const assessment = 'assessment';
export const nutritionRoute = 'nutrition';
export const management = 'management';
export const screening = 'screening';
export const whoResourcesRoute = 'who-reference-resources';

export const toolsRoute = 'tools';
export const countryAssignmentsRoute = 'country-zone-assignments';
export const provinceAssignmentsRoute = 'province-zone-assignments';
export const exceedingQuota = 'exceeding-quota';
export const generateCountryXmlRoute = 'generate-country-xml';
export const generatePartnersXmlRoute = 'generate-partners-xml';
export const generateCountryJsonRoute = 'generate-country-json';
export const generatePartnersJsonRoute = 'generate-partners-json';
export const suspiciousRecords = 'suspicious-records';
export const suspiciousRecordToReviewRoute = 'suspicious-record-to-review';
export const printRoute = 'print';
export const newPatientRoute = `/${patientRoute}/${newRoute}`;
export const suspiciousRecordsFull = `${toolsRoute}/${suspiciousRecords}/${reviewRoute}`;
export const rejectionReasonsRoute = 'problem/rejection-reasons';
export const refreshStatisticsRoute = 'refresh-statistics';
export const refreshSuspiciousRecords = 'refresh-suspicious-records';
export const matchPatients = 'match-patients';

export const resourcesRoute = 'resources';
export const cleftPublications = 'cleft-publications';
export const cleftPublicationsFullRoute = `${resourcesRoute}/${cleftPublications}`;
export const medicalFormsRoute = 'medical-forms';
export const videosRoute = 'videos';
export const protocolsRoute = 'protocols-advisories-and-guidelines';
export const resourcesForFamiliesRoute = 'resources-for-families';
export const userGuideRoute = 'user-guide';
export const speechResourcesRoute = speechRoute;
export const speechResourcesFullRoute = `${resourcesRoute}/${speechRoute}`;
export const nutritionResourcesRoute = nutritionRoute;
export const nutritionAssessmentResourcesRoute = `${nutritionRoute}/${assessment}`;
export const nutritionFollowUpPilotResourcesRoute = `${nutritionRoute}/${followUp}`;
export const nutritionResourcesFullRoute = `${resourcesRoute}/${nutritionRoute}`;

export const educationAndResearchRoute = 'education-and-research';
export const educationAndResearchFullRoute = `${resourcesRoute}/${educationAndResearchRoute}`;

export const offlineAppRoute = 'offline-app';
export const uploadOfflineRecordRoute = `${offlineAppRoute}/upload`;
export const practitioners = 'practitioners';
export const allQaResults = 'results';

export const imageViewerRoute = 'image';
export const nutritionReportRoute = `${nutritionRoute}`;
export const surgicalQaRoute = 'qa-surgical';
export const speechQaRoute = 'qa-speech';
export const orthoQaRoute = 'qa-ortho';
export const psioRoute = 'psio';
export const mdRoute = 'md';
export const pdRoute = 'pd';
export const orthoQaPsioRoute = `${orthoQaRoute}/${psioRoute}`;
export const orthoQaMdRoute = `${orthoQaRoute}/${mdRoute}`;
export const orthoQaPdRoute = `${orthoQaRoute}/${pdRoute}`;

export const mainDashboardFullRoute = '/';

export class RoutingUtils {
  static getSerializedRootPath(route: ActivatedRouteSnapshot): string {
    let absoluteUrl = route.pathFromRoot.map(value => value.url.map(segment => segment.path).join('/')).join('/');
    if (Object.keys(route.queryParams)?.length) {
      const queryParams = Object.keys(route.queryParams)
        .map(key => `${key}=${route.queryParams[key]}`)
        .join('&');
      absoluteUrl = `${absoluteUrl}?${queryParams}`;
    }
    return absoluteUrl;
  }
}
