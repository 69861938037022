import { OrthoStageValidationData } from '@/src/app/features/ortho/models/ortho-stage-validation-data.model';
import { OrthoFormApiBase } from '@/src/app/features/ortho/shared/ortho-form-api-base';
import { BaseTreatment, TreatmentId } from '@/src/app/features/surgical/models/base-treatment.model';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

export abstract class OrthoAssessmentFormApi<OrthoAssessmentModel extends BaseTreatment> extends OrthoFormApiBase<OrthoAssessmentModel> {
  constructor(httpClient: HttpClient, apiRoute: string) {
    super(httpClient, apiRoute);
  }
  override getValidationData(patientId: number, assessmentId?: TreatmentId): Observable<OrthoStageValidationData> {
    if (!assessmentId) {
      return of(null);
    }
    return this.httpClient.get<OrthoStageValidationData>(`${this.baseUrl}/${assessmentId}/validation-data`);
  }
}
