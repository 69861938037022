import { BaseHttpService } from '@shared/services/http/base-http.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NutritionStageValidationData } from '@src/app/features/nutrition-management/models/nutrition-stage-validation-data.model';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

export abstract class BaseNutritionManagementService<BaseNutritionManagement> extends BaseHttpService {
  protected constructor(httpClient: HttpClient, nutritionStageRootUrl: string) {
    super(httpClient, { baseUrl: nutritionStageRootUrl });
  }

  saveNutritionManagement(nutritionManagement: BaseNutritionManagement): Observable<BaseNutritionManagement> {
    return this.httpClient.post<BaseNutritionManagement>(`${this.baseUrl}/save`, nutritionManagement);
  }

  submitNutritionManagement(nutritionManagement: BaseNutritionManagement): Observable<BaseNutritionManagement> {
    return this.httpClient.post<BaseNutritionManagement>(`${this.baseUrl}/submit`, nutritionManagement);
  }

  getNutritionManagement(id: TreatmentId): Observable<BaseNutritionManagement> {
    return this.httpClient.get<BaseNutritionManagement>(`${this.baseUrl}/${id}`);
  }

  unlockNutritionManagement(id: TreatmentId): Observable<void> {
    return this.httpClient.patch<void>(`${this.baseUrl}/unlock/${id}`, null);
  }

  deleteNutritionManagement(id: TreatmentId): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${id}`);
  }

  getValidationData(patientId: number): Observable<NutritionStageValidationData> {
    return this.httpClient.get<NutritionStageValidationData>(`${this.baseUrl}/${patientId}/validation-data`);
  }
}
