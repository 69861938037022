import { PatientService } from '@/src/app/features/patient/patient.service';
import { LanguagePair } from '@/src/app/shared/components/language-switcher/language.model';
import { NoticeType } from '@/src/app/shared/components/notice/notice.model';
import { StatusBarBaseComponent } from '@/src/app/shared/components/treatment/components/status-bar-base/status-bar-base.component';
import { TreatmentModalComponent } from '@/src/app/shared/components/treatment/components/treatment-status-bar/components/treatment-modal/treatment-modal.component';
import {
  DialogMode,
  statusBarConfig,
  StatusBarConfigData,
  StatusConfigData
} from '@/src/app/shared/components/treatment/components/treatment-status-bar/treatment-status-bar.utils';
import { isSameDay } from '@/src/app/utils/date.utils';
import { FileExtension } from '@/src/app/utils/file.utils';
import { formStatusMap } from '@/src/app/utils/form.utils';
import { alternativeMappings, languages } from '@/src/app/utils/language.utils';
import { getStaticFileUrl } from '@/src/app/utils/media.utils';
import { intervention, surgicalRoute } from '@/src/app/utils/routing.utils';
import { mapToStatusEnum } from '@/src/app/utils/treatment.utils';
import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FormStatus } from '@shared/enums/form-status.enum';
import { BaseTreatment } from '@src/app/features/surgical/models/base-treatment.model';
import { GlobalErrorHandlerService } from 'src/app/shared/services/global-error-handler.service';
import { TreatmentBusinessRules } from '@src/app/business-rules/treatment.business-rules';

@Component({
  selector: 'stx-treatment-status-bar',
  templateUrl: './treatment-status-bar.component.html',
  styleUrls: ['./treatment-status-bar.component.scss']
})
export class TreatmentStatusBarComponent extends StatusBarBaseComponent implements OnChanges {
  readonly photoStandardsKey = 'problem_type_lookup.description.photo_standard_not_met';
  readonly duplicateRecordKey = 'problem_type_lookup.description.duplicate';
  readonly photoStandards = 'Photo_Standards';

  private _treatment: BaseTreatment;
  get treatment() {
    return this._treatment;
  }
  @Input() set treatment(value: BaseTreatment) {
    if (value) {
      this._treatment = value;
    }
  }

  @Input() internalSuspiciousComment: string;

  noticeType: NoticeType;
  statusConfig: StatusConfigData;
  comment: string;

  constructor(
    dialog: MatDialog,
    private readonly globalErrorHandler: GlobalErrorHandlerService,
    translateService: TranslateService,
    cd: ChangeDetectorRef,
    patientService: PatientService
  ) {
    super(dialog, translateService, cd, patientService);
  }

  getDuplicateRecordLink(dupId: number) {
    return `/${surgicalRoute}/${intervention}/${dupId}`;
  }

  get hasRejectionProblems() {
    return this._treatment.status === formStatusMap.get(FormStatus.REJECTED) && !!this._treatment?.problems?.length;
  }

  get showNonEditableInfo(): boolean {
    return (
      TreatmentBusinessRules.treatmentInProgress(this._treatment) &&
      TreatmentBusinessRules.treatmentEditableBasedOnScope(this._treatment) &&
      !this._treatment.editable
    );
  }

  get showModificationDate(): boolean {
    return (
      this._treatment.modified &&
      ((!this._treatment.submitted && !isSameDay(this._treatment.created, this._treatment.modified)) ||
        (this._treatment.submitted && !isSameDay(this._treatment.submitted, this._treatment.modified)))
    );
  }

  get rejectionComment(): string {
    if (this._treatment.status === formStatusMap.get(FormStatus.REJECTED) && this._treatment?.problems) {
      return this._treatment?.problems.find(problem => problem.comment)?.comment;
    }
    return null;
  }

  get internalSuspiciousRejectionComment(): string {
    return this.internalSuspiciousComment ?? null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.treatment && changes.treatment.currentValue) {
      this.initialiseStatusBar(changes.treatment.currentValue);
    }
  }

  openUnlockDialog() {
    this.openDialog(DialogMode.UNLOCK);
  }

  openDeleteDialog(): void {
    this.openDialog(DialogMode.DELETE_TREATMENT);
  }

  private openDialog(dialogMode: DialogMode): void {
    const unlockDialogRef = this.dialog.open(TreatmentModalComponent, { autoFocus: false, data: { mode: dialogMode } });
    let confirmAction: () => void = () => {
      // used for emiting events
    };

    if (dialogMode === DialogMode.DELETE_TREATMENT) {
      confirmAction = () => this.deleteClicked.emit();
    } else {
      confirmAction = () => this.unlockClicked.emit();
    }
    unlockDialogRef.afterClosed().subscribe(result => {
      if (result?.confirm) {
        confirmAction();
      }
    });
  }

  private initialiseStatusBar(baseTreatment: BaseTreatment): void {
    this.initialiseBarStyleAndStatus(baseTreatment);
  }

  private initialiseBarStyleAndStatus(baseTreatment: BaseTreatment): void {
    const treatmentStatus: FormStatus = mapToStatusEnum(baseTreatment.status);
    if (statusBarConfig.has(treatmentStatus)) {
      const statusBar: StatusBarConfigData = statusBarConfig.get(treatmentStatus);
      this.noticeType = statusBar.noticeType;
      this.statusConfig = statusBar.statusConfig;
    } else {
      this.globalErrorHandler.handleErrorAndInformUser(new Error('Error during treatment status bar initialization'));
    }
  }
  get photoStandardsLink(): string {
    return getStaticFileUrl(this.getTranslatedPDFName(this.photoStandards, this.translateService.currentLang));
  }

  private getTranslatedPDFName(fileName: string, lang: string) {
    const langShortcutSearch = (lp: LanguagePair) => lp.name === lang;
    const languageShortcut = (alternativeMappings.find(langShortcutSearch) ?? languages.find(langShortcutSearch))?.shortcut ?? 'en';
    return `pdf/${fileName}_${languageShortcut}${FileExtension.PDF}`;
  }
}
