import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { CommonModule } from '@angular/common';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { PaginationSortingModel } from '@shared/components/table/models/pagination-sorting.model';
import { ReportDownloaderComponentModule } from '../../components/report-downloader/report-downloader.component';
import { ReportConfig } from '../../components/report-filters/models/report-filters.model';
import { ReportFiltersComponent, ReportFiltersModule } from '../../components/report-filters/report-filters.component';
import { ReportTableDataService } from '../../components/report-filters/services/report-table-data.service';
import { ReportService } from '../../components/report-filters/services/report.service';
import { ReportHeaderComponentModule } from '../../components/report-header/report-header.component';
import { OrthodonticTreatmentCompactReportTableComponentModule } from './components/orthodontic-treatments-compact-report-table/orthodontic-treatments-compact-report-table.component';
import { AuthService } from '@src/app/features/authentication/services/auth.service';
import { PermissionEnum } from '@core/permissions/permission.enum';

@Component({
  selector: 'stx-orthodontic-treatments-report',
  templateUrl: './orthodontic-treatments-compact-report.component.html'
})
export class OrthodonticTreatmentsCompactReportComponent extends BaseComponent implements OnInit {
  @ViewChild(ReportFiltersComponent) reportFiltersComponent: ReportFiltersComponent;
  readonly reportConfig: ReportConfig = {
    type: 'ORTHODONTIC_TREATMENTS_COMPACT',
    downloadOptions: {},
    filters: {
      recordDate: true,
      gender: true,
      age: true,
      latestCases: true,
      orthoTypes: true,
      deIdentification: true,
      practitioners: true,
      orthoQaGrading: this.authService.checkUserPermission(PermissionEnum.VIEW_REPORT_QA_FILTER),
      qaOnly: this.authService.checkUserPermission(PermissionEnum.VIEW_REPORT_QA_FILTER),
      geography: true,
      organizationsTree: true
    }
  };
  showPagination = true;
  deIdentified = false;
  constructor(
    public reportService: ReportService,
    private reportTableDataService: ReportTableDataService,
    private authService: AuthService
  ) {
    super();
  }

  applyFilters(pagination: PaginationSortingModel) {
    this.showPagination = this.reportService.latestCasesFilterActive();
    this.deIdentified = !!this.reportService.reportFiltersFormGroup.get('deIdentification')?.value;
    this.reportTableDataService.prepareList('ORTHODONTIC_TREATMENTS_COMPACT', this.reportService.model, pagination);
  }

  ngOnInit(): void {
    this.subSink.sink = this.reportService.initialized$.subscribe(value => {
      if (value && this.reportService.reportFiltersFormGroup.status !== 'VALID') {
        this.reportService.showFilters();
      }
    });
  }
}

@NgModule({
  declarations: [OrthodonticTreatmentsCompactReportComponent],
  imports: [
    ReportFiltersModule,
    TranslateModule,
    ReportDownloaderComponentModule,
    CommonModule,
    OrthodonticTreatmentCompactReportTableComponentModule,
    ReportHeaderComponentModule,
    GeneralCommonsModule
  ],
  exports: [OrthodonticTreatmentsCompactReportComponent]
})
export class OrthodonticTreatmentCompactReportComponentModule {}
