import { AuthService } from '@/src/app/features/authentication/services/auth.service';
import { MediaUrl } from '@/src/app/features/media/models/media-url.model';
import { AllowedTreatmentsConfig, ExternalTreatmentLink } from '@/src/app/features/patient/models/allowed-treatments-config.model';
import { DictionaryTranslation, PatientListItem, ReleaseForms } from '@/src/app/features/patient/models/patient.model';
import { PatientService } from '@/src/app/features/patient/patient.service';
import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import { NoticeComponentModule } from '@/src/app/shared/components/notice/notice.component';
import { PatientDetailsHeadingComponentModule } from '@/src/app/shared/components/patient-details-heading/patient-details-heading.component';
import { PatientDocumentUploadModule } from '@/src/app/shared/components/patient-document-upload/patient-document-upload.module';
import { PatientMatchingModule } from '@/src/app/shared/components/patient-matching/patient-matching.module';
import { PatientTreatmentComponentModule } from '@/src/app/shared/components/patient-treatment/patient-treatment.component';
import { TreatmentModalComponent } from '@/src/app/shared/components/treatment/components/treatment-status-bar/components/treatment-modal/treatment-modal.component';
import { DialogMode } from '@/src/app/shared/components/treatment/components/treatment-status-bar/treatment-status-bar.utils';
import { TreatmentType } from '@/src/app/shared/components/treatment/treatment.enum';
import { FormType } from '@/src/app/shared/enums/form-type.enum';
import { ParentOrderName } from '@/src/app/shared/enums/parent-order-name.enum';
import { PatientDetailsBlockMode } from '@/src/app/shared/enums/patient-details-block.enum';
import { CountryDictionary } from '@/src/app/shared/models/geo.model';
import { MediaResource } from '@/src/app/shared/models/media-resource.model';
import { ReadonlyCommonsModule } from '@/src/app/shared/modules/readonly-commons/readonly-commons.module';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Params, Router, RouterModule } from '@angular/router';
import { PermissionEnum } from '@core/permissions/permission.enum';
import { PermissionModule } from '@core/permissions/permission.module';
import { TranslateModule } from '@ngx-translate/core';
import { PatientActionType } from '@shared/components/patient-details-actions/patient-action-type.enum';
import { PatientDataForReleaseForm } from '@shared/components/patient-document-upload/patient-release-form.model';
import { GeneralCommonsModule } from '@shared/modules/general-commons/general-commons.module';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { ArrayUtils } from '@utils/array.utils';
import { patientStatusDraftValue, PatientUtils } from '@utils/patient.utils';
import { patientHistoryPath, patientRoute } from '@utils/routing.utils';
import { scrollToElement, scrollToTop } from '@utils/scroll.utils';
import { externalTreatmentTranslationKeys, getTreatmentTypeData, treatmentTypeBySection } from '@utils/treatment.utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { PrfValidationService } from '@src/app/features/patient/services/prf-validation.service';
import { ExternalTreatmentType } from '@src/app/features/patient/models/external-treatment-type.enum';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'stx-patient-details-block',
  templateUrl: './patient-details-block.component.html',
  styleUrls: ['./patient-details-block.component.scss']
})
export class PatientDetailsBlockComponent extends BaseComponent implements OnInit, AfterViewInit {
  @Input() patient: PatientListItem;
  @Input() genders: DictionaryTranslation[] = [];
  @Input() countries: Array<CountryDictionary> = [];
  @Input() mode: PatientDetailsBlockMode;
  @Output() releaseFormUpdated = new EventEmitter<void>();

  areAllowedTreatmentsLoaded = false;

  private readonly RED_CAP_ORAL_HEALTH_LINK_PREFIX = 'ORAL_HEALTH';
  private readonly RED_CAP_ENT_LINK_PREFIX = 'ENT';

  potentialMatches: PatientListItem[];
  isExpanded = false;
  patientPhotoUrl: MediaResource | null;
  isPatientCloneRequired: boolean;
  allowedTreatments: TreatmentType[][] = [];
  patientIdsForNewTreatment: Map<TreatmentType, number>;
  releaseForms: Array<MediaUrl>;
  patientLink: string;
  newTreatmentExpanded = false;

  showReleaseForm = false;

  redCapEntLinks: ExternalTreatmentLink[];
  redCapOralHealthLinks: ExternalTreatmentLink[];

  showRedCapEntLinks = false;
  showRedCapOralHealthLinks = false;

  readonly externalTreatmentKeys = externalTreatmentTranslationKeys;
  readonly PermissionEnum = PermissionEnum;
  readonly treatmentTypeBySections = treatmentTypeBySection;
  readonly PatientDetailsBlockMode = PatientDetailsBlockMode;
  readonly getTreatmentTypeData = getTreatmentTypeData;
  readonly patientDataForReleaseForm: () => Observable<PatientDataForReleaseForm> | null;
  readonly releaseFormFormGroup: UntypedFormGroup;
  readonly focusElementQuery = 'stx-patient-release-form-online stx-document-language-select ul li:first-child div button';

  private readonly _focusPrfBlockEvent$ = new BehaviorSubject<boolean>(false);
  get focusPrfBlockTrigger$() {
    return this._focusPrfBlockEvent$.asObservable();
  }

  get printPatientRoute(): string {
    return patientHistoryPath(this.patient.id);
  }

  get isDraft(): boolean {
    return this.patient.status === patientStatusDraftValue;
  }

  get shouldDisplayNewTreatmentBtn(): boolean {
    return PatientUtils.isPhysicalPatientValidForNewTreatment(this.patient) || this.isPatientCloneRequired;
  }

  get shouldShowNoPRFNotice(): boolean {
    return ArrayUtils.isEmpty(this.releaseForms);
  }

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly patientService: PatientService,
    private readonly router: Router,
    private readonly wsHelper: WsHelperService,
    private prfValidationService: PrfValidationService
  ) {
    super();
    this.patientDataForReleaseForm = () => {
      return this.patientService.getPatientById(this.patient.patientIdForNewReleaseForm) as Observable<PatientDataForReleaseForm>;
    };
    this.releaseFormFormGroup = new UntypedFormGroup({
      onlineReleaseForm: new UntypedFormControl(),
      offlineReleaseForm: new UntypedFormControl()
    });
    this.subSink.sink = this.releaseFormFormGroup.valueChanges.subscribe((value: ReleaseForms) => {
      if (value.onlineReleaseForm || value.offlineReleaseForm) {
        this.subSink.sink = this.wsHelper
          .callWithSpinner(this.patientService.attachReleaseForm(this.patient.patientIdForNewReleaseForm, value))
          .subscribe(_ => {
            this.releaseFormFormGroup.reset();
            this.showReleaseForm = false;
            this.changeDetector.markForCheck();
            scrollToTop();
            this.updatePatient();
          });
      }
    });
  }

  ngOnInit(): void {
    this.patientPhotoUrl = this.getPatientPhotoUrl();
    this.subSink.sink = this.activatedRoute.data.subscribe(data => {
      if (data.formType === FormType.PATIENT && this.authService.checkUserPermission(PermissionEnum.MATCH_PATIENTS)) {
        this.getPotentialMatches(this.patient.id);
      }
    });

    this.subSink.sink = this.activatedRoute.queryParams.subscribe(queryParams => this.parseQueryParams(queryParams));

    this.subSink.sink = this.patientService.updatePatientsEventListener().subscribe((patientActionType: PatientActionType) => {
      if (patientActionType === PatientActionType.DELETE) {
        return;
      }

      if (this.mode === PatientDetailsBlockMode.FORM_ON_TOP) {
        this.updatePatient();
      }
    });
    this.isPatientCloneRequired = this.getPatientCloneRequiredState();
    this.releaseForms = PatientUtils.getPatientReleaseForms(this.patient);
    this.patientLink = PatientUtils.getPatientUrl(this.patient);
  }

  ngAfterViewInit() {
    if (this.showReleaseForm) {
      scrollToElement('stx-patient-release-form-block');
    }
  }

  private parseQueryParams(queryParams: Params) {
    if (queryParams.showReleaseForm) {
      this.showReleaseForm = true;
      this.changeDetector.detectChanges();
    }
    this.isExpanded = this.mode !== PatientDetailsBlockMode.FORM_ON_TOP || this.showReleaseForm === true;
    if (this.showReleaseForm) {
      this._focusPrfBlockEvent$.next(true);
    }

    setTimeout(() => {
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  getPotentialMatches(patientId: number): void {
    this.wsHelper.callWithSpinner(this.patientService.getPotentialMatches(patientId)).subscribe(matches => {
      this.potentialMatches = matches;
      this.changeDetector.detectChanges();
    });
  }

  getAllowedTreatments(): void {
    this.areAllowedTreatmentsLoaded = false;
    if (PatientUtils.isPhysicalPatientValidForNewTreatment(this.patient)) {
      this.wsHelper
        .callWithSpinner(this.patientService.getAllowedTreatmentsToPatientIds(this.patient.patientIdForNewTreatment))
        .subscribe(allowedTreatmentsConfig => {
          this.patientIdsForNewTreatment = allowedTreatmentsConfig.allowedNewTreatments;
          this.allowedTreatments = this.treatmentTypeBySections
            .map(treatmentTypesGroup =>
              treatmentTypesGroup.filter(
                treatmentType =>
                  this.patientIdsForNewTreatment[treatmentType] &&
                  this.authService.checkUserPermission(this.getPermissionToAdd(treatmentType))
              )
            )
            .filter(treatmentGroup => treatmentGroup.length);
          this.areAllowedTreatmentsLoaded = true;

          this.setRedCapLinks(allowedTreatmentsConfig);

          this.changeDetector.detectChanges();
        });
    }
  }

  private setRedCapLinks(allowedTreatmentsConfig: AllowedTreatmentsConfig) {
    this.redCapEntLinks = allowedTreatmentsConfig.allowedNewExternalTreatments.filter(link =>
      link.treatmentType.startsWith(this.RED_CAP_ENT_LINK_PREFIX)
    );

    this.showRedCapEntLinks = this.redCapEntLinks
      .map(link => this.authService.checkUserPermission(this.getPermissionToAdd(link.treatmentType)))
      .some(Boolean);

    this.redCapOralHealthLinks = allowedTreatmentsConfig.allowedNewExternalTreatments.filter(link =>
      link.treatmentType.startsWith(this.RED_CAP_ORAL_HEALTH_LINK_PREFIX)
    );

    this.showRedCapOralHealthLinks = this.redCapOralHealthLinks
      .map(link => this.authService.checkUserPermission(this.getPermissionToAdd(link.treatmentType)))
      .some(Boolean);
  }

  getPermissionToAdd(treatmentType: TreatmentType | ExternalTreatmentType): PermissionEnum {
    return PermissionEnum[`SAVE_${treatmentType}`];
  }

  openDialogForClone() {
    const unlockDialogRef = this.dialog.open(TreatmentModalComponent, { autoFocus: false, data: { mode: DialogMode.CLONE } });
    unlockDialogRef.afterClosed().subscribe(result => {
      if (result?.confirm) {
        this.clonePatient();
      }
    });
  }

  private updatePatient() {
    this.wsHelper.call(this.patientService.getPatientById(this.patient.id)).subscribe(patient => {
      this.patient = patient.patientDetailsBlockPatient;
      this.releaseForms = PatientUtils.getPatientReleaseForms(this.patient);
      this.changeDetector.detectChanges();
    });
  }

  private clonePatient() {
    this.router.navigate([`${patientRoute}/new`], { queryParams: { patientIdToClone: this.patient.id } });
  }

  private getPatientCloneRequiredState(): boolean {
    let isAnyPatientFromCurrentPartner = false;

    if (this.patient.patientTreatments.length) {
      isAnyPatientFromCurrentPartner = this.patient.patientTreatments.some(patientTreatment => patientTreatment.partner.id);
    }

    return !isAnyPatientFromCurrentPartner;
  }

  private getPatientPhotoUrl(): MediaResource | null {
    return this.patient.mediaResources?.find(
      (mediaResource: MediaResource) => mediaResource.parentOrderName === ParentOrderName.PATIENT_PHOTO
    );
  }

  showReleaseFormBlock() {
    this.prfValidationService.validatePrfData();

    if (this.releaseForms.length === 0 && this.mode === PatientDetailsBlockMode.LIST) {
      this.router.navigate([`${patientRoute}/${this.patient.id}`]);
    } else {
      if (this.isDraft && this.patientDataForReleaseForm()) {
        return;
      }
      this.showReleaseForm = !this.showReleaseForm;
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    FlexLayoutModule,
    MatIconModule,
    TranslateModule,
    RouterModule,
    MatTooltipModule,
    MatButtonModule,
    ReadonlyCommonsModule,
    PatientTreatmentComponentModule,
    PatientDocumentUploadModule,
    MatDividerModule,
    PatientMatchingModule,
    PermissionModule,
    PatientDetailsHeadingComponentModule,
    ReactiveFormsModule,
    GeneralCommonsModule,
    NoticeComponentModule
  ],
  declarations: [PatientDetailsBlockComponent],
  exports: [PatientDetailsBlockComponent]
})
export class PatientDetailsBlockComponentModule {}
