<footer class="p-t-20 p-b-20 footer">
  <div class="m-r-15 m-l-15 mt-25 flex-row-container">
    <div>
      <p>Smile Train</p>
      <p>633 Third Avenue</p>
      <p>9th Floor</p>
      <p>New York, NY 10017</p>
      <a href="mailto:stxadmin@smiletrain.org" class="email-link">stxadmin@smiletrain.org</a>
      <div class="flex-row-container gap-7">
        <a href="https://www.facebook.com/SmileTrain" target="_blank"><mat-icon svgIcon="fb"></mat-icon></a>
        <a href="https://twitter.com/smiletrain" target="_blank"><mat-icon svgIcon="twitter"></mat-icon></a>
        <a href="https://www.instagram.com/smiletrain" target="_blank"><mat-icon svgIcon="insta"></mat-icon></a>
        <a href="https://www.youtube.com/user/TheSmileTrainCharity" target="_blank"><mat-icon svgIcon="yt"></mat-icon></a>
        <a href="https://www.snapchat.com/add/SmileTrainHQ" target="_blank"><mat-icon svgIcon="snap"></mat-icon></a>
      </div>
    </div>
  </div>

  <div class="m-r-15 footer-links">
    <stx-static-file-link file="pdf/STX_Terms_of_Use_{lang}.pdf"> {{ 'footer.terms_of_use' | translate }}</stx-static-file-link>
    <stx-value-separator value=" | "></stx-value-separator>
    <stx-static-file-link file="pdf/STX_Privacy_Policy_{lang}.pdf">{{ 'footer.privacy_policy' | translate }}</stx-static-file-link>
  </div>
</footer>
