import { Injectable } from '@angular/core';

import { StringUtils } from '@utils/string.utils';

import { HttpErrorResponse } from '@angular/common/http';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';
import { SeverityLevel, User, captureException } from '@sentry/angular-ivy';

export const unknownErrorMessage = 'Unknown error occurred';

interface Context {
  user: User;
  level: SeverityLevel;
}

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  constructor(private readonly currentUserService: CurrentUserService) {} //TODO#40353 - Fix circular dep

  error(message: unknown, ...optionalParams: any[]) {
    console.error(message, optionalParams);

    this.logErrorToSentry(message);
  }

  private logErrorToSentry(message: unknown) {
    const context = this.buildContext('error');

    if (LoggerService.hasStackProperty(message)) {
      captureException(message, context);
    } else {
      let errorMessage: string;
      if (StringUtils.isString(message)) {
        errorMessage = message as string;
      } else {
        errorMessage = LoggerService.getErrorMessageFromNonStringObject(message);
      }
      const tempErrorForStackTrace = new Error(errorMessage);
      captureException(tempErrorForStackTrace, context);
    }
  }

  private static getErrorMessageFromNonStringObject(message: unknown): string {
    try {
      return JSON.stringify(message);
    } catch (error: any) {
      if (message instanceof HttpErrorResponse) {
        return `HttpErrorResponse. Message: ${message.message}; URL: ${message.url}, Status: ${message.status}`;
      } else {
        return unknownErrorMessage;
      }
    }
  }

  private buildContext(severityLevel: SeverityLevel): Partial<Context> {
    return {
      user: this.buildUserContext(),
      level: severityLevel
    } as Partial<Context>;
  }

  private buildUserContext(): User | null {
    const currentUser = this.currentUserService.getCurrentUser();
    if (!currentUser) {
      return null;
    }
    return {
      id: currentUser.id?.toString(),
      email: currentUser.email,
      username: currentUser.email,
      currentProfileId: currentUser.activeProfile?.id,
      permissions: currentUser.permissions?.map(permission => permission?.name)
    } as User;
  }

  private static hasStackProperty(object: unknown): boolean {
    return object instanceof Error || !!(object as any)?.stack;
  }
}
