import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';
import { dateRangeToBigErrorName } from '@shared/validation/validation.constants';
import { PasswordValidatorService } from '@shared/validation/password-validator/password-validator.service';

export function errorIf(condition: boolean, key: string, errorMsg: string = 'error.field_empty'): ValidationErrors | null {
  if (condition) {
    return {
      [key]: {
        message: errorMsg
      }
    };
  }
  return null;
}

export function invalidIf(predicate: (value) => boolean, errorMsg: string = 'error.field_empty'): ValidatorFn {
  return formControl => {
    if (!formControl.parent) {
      return null;
    }
    return errorIf(predicate(formControl.value), 'invalidIf', errorMsg);
  };
}

export function selectionInvalidIf(predicate: () => boolean): ValidatorFn {
  return invalidIf(predicate, 'error.select');
}

export function requiredIfValidator(predicate: () => boolean): ValidatorFn {
  return formControl => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      return StxValidators.required(formControl);
    }
    return null;
  };
}

export function atLeastOneRequired(
  controlNames: string[],
  errorName = 'atLeastOneRequired',
  conditionalControlName?: string,
  conditionalControlNameValue?: any
): ValidatorFn {
  return (formGroup: UntypedFormGroup): { [key: string]: any } | null => {
    if (conditionalControlName && !formGroup.get(conditionalControlName)) {
      return null;
    }
    if (conditionalControlName && formGroup.get(conditionalControlName) && formGroup.get(conditionalControlName).value === null) {
      return null;
    }
    if (
      conditionalControlNameValue !== null &&
      formGroup.get(conditionalControlName) &&
      formGroup.get(conditionalControlName).value !== conditionalControlNameValue
    ) {
      return null;
    }

    const anyControlHasValueAndIsNotFalsy = controlNames.some(
      controlName => StxValidators.required(formGroup.get(controlName)) === null && Boolean(formGroup.get(controlName).value)
    );

    return anyControlHasValueAndIsNotFalsy ? null : { [errorName]: true };
  };
}

export function checkPasswords(group: UntypedFormGroup): ValidationErrors | null {
  const pass = group.get('password').value;
  const confirmPassControl = group.get('passwordConfirm');
  const confirmPass = confirmPassControl.value;
  if (pass === confirmPass) {
    confirmPassControl.setErrors(null);
    return null;
  } else {
    confirmPassControl.setErrors({ passswordsNotMatch: true });
    return { passswordsNotMatch: true };
  }
}

export function isValidUrl(control: AbstractControl): ValidationErrors | null {
  if (control.value) {
    try {
      new URL(control.value);
    } catch (e) {
      return {
        pattern: {
          message: true
        }
      };
    }
  }
  return null;
}

export function isValidPassword(control: AbstractControl): ValidationErrors | null {
  const password = control.value;
  const passwordValidationService = new PasswordValidatorService();

  if (!passwordValidationService.validatePasswordAndGetResult(password).isValid) {
    return {
      violatedRules: passwordValidationService.validatePasswordAndGetResult(password).violatedRules
    };
  }

  return null;
}

export class StxValidators {
  public static readonly requiredErrorName = 'required';
  private static readonly isNotDecimal: ValidatorFn = Validators.pattern('^[0-9]*$');
  public static readonly isInteger: ValidationErrors | null = Validators.compose([
    StxValidators.isNotDecimal,
    Validators.min(-2147483647),
    Validators.max(2147483647)
  ]);
  public static readonly isInShortTypeRange: ValidationErrors | null = Validators.compose([
    Validators.min(-32768),
    Validators.max(32767),
    StxValidators.isNotDecimal
  ]);
  public static readonly maxLength64 = Validators.maxLength(64);

  /**
   * Requires the control to have a non-empty value and a value that isn't blank.
   */
  static required(control: AbstractControl): ValidationErrors | null {
    const emptyValueErrors = Validators.required(control);

    if (!emptyValueErrors) {
      const blankStringRegEx = new RegExp(/^\s+$/);
      const isBlankField = blankStringRegEx.test(String(control.value));

      return isBlankField
        ? {
            pattern: true
          }
        : null;
    }
    return emptyValueErrors;
  }

  static maxDatesDifference(dateFromControlName: string, dateToControlName: string, maxDifferenceInYears: number): ValidatorFn {
    return (formGroup: UntypedFormGroup): { [key: string]: any } | null => {
      const fromDate: moment.Moment = formGroup.get(dateFromControlName).value;
      const toDate: moment.Moment = formGroup.get(dateToControlName).value;
      if (!fromDate || !toDate || fromDate.isAfter(toDate)) {
        return null;
      }
      if (toDate.diff(fromDate, 'years', true) <= maxDifferenceInYears) {
        return null;
      }
      return {
        [dateRangeToBigErrorName]: true
      };
    };
  }
}
