<ng-container *ngIf="dataSource?.data.length">
  <mat-paginator
    [length]="totalResults"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [pageIndex]="currentPageIndex"
    (page)="changePage($event)"
  ></mat-paginator>
  <div class="data-table-container">
    <div class="data-table-wrapper">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z2" aria-describedby="data-table-container">
        <ng-container matColumnDef="orthoType">
          <th mat-header-cell *matHeaderCellDef>{{ 'reports.orthodontic.ortho_treatment_type' | translate }}</th>
          <td mat-cell *matCellDef="let recordData">
            <stx-simple-report-cell
              [inlineInputsToTranslate]="[orthoTypeToTranslationKeyMap[recordData.interventionType]]"
            ></stx-simple-report-cell>
          </td>
        </ng-container>

        <ng-container matColumnDef="treatmentCenter">
          <th mat-header-cell *matHeaderCellDef>{{ 'treatment_center.treatment_center' | translate }}</th>
          <td class="wrap-content" mat-cell *matCellDef="let recordData">
            <stx-simple-report-cell
              [rowInputs]="[recordData.treatmentCenter, recordData.country, recordData.province]"
            ></stx-simple-report-cell>
          </td>
        </ng-container>

        <ng-container matColumnDef="patient">
          <th mat-header-cell *matHeaderCellDef>{{ 'patient.patient' | translate }}</th>
          <td mat-cell *matCellDef="let recordData">
            <div class="align-middle flex-row-container">
              <stx-patient-link [showIcon]="false" [patientId]="recordData.patientId">
                <stx-simple-report-cell [rowInputs]="[recordData.hospitalRecordId]"></stx-simple-report-cell>
              </stx-patient-link>
              <stx-patient-link [patientHistoryRoute]="true" [patientId]="recordData.patientId"> </stx-patient-link>
            </div>
            <stx-simple-report-cell
              [rowInputs]="[
                recordData.hospitalRecordIdLoc,
                recordData.patientName,
                recordData.patientLocalName | brackets,
                recordData.patientId
              ]"
              [inlineInputsToTranslate]="[recordData.gender]"
              [inlineInputs]="[recordData.dateOfBirth]"
            ></stx-simple-report-cell>
          </td>
        </ng-container>

        <ng-container matColumnDef="assessment">
          <th mat-header-cell *matHeaderCellDef>{{ 'ortho.assessment' | translate }}</th>
          <td mat-cell *matCellDef="let recordData">
            <stx-treatment-status-indicator
              [addBottomPadding]="true"
              [showLabel]="false"
              [treatmentStatus]="recordData.assessmentStatus"
            ></stx-treatment-status-indicator>
            <a
              [routerLink]="['/' + orthoRoute + '/' + orthoRoutes[recordData.interventionType].assessment + '/' + recordData.assessmentId]"
              [queryParams]="{ patientId: recordData.patientId }"
              class="flex-center fs-14"
            >
              <stx-treatment-date
                class="bold"
                [direction]="'row'"
                [createDate]="recordData.assessmentSubmitted"
                [submitDate]="recordData.assessmentCreated"
              ></stx-treatment-date>
            </a>
            <stx-simple-report-cell
              [rowInputs]="[
                recordData.assessmentDate,
                recordData.assessmentOrthodontistName,
                recordData.assessmentOrthodontistNameLoc | brackets
              ]"
            ></stx-simple-report-cell>
          </td>
        </ng-container>
        <ng-container matColumnDef="treatment">
          <th mat-header-cell *matHeaderCellDef>{{ 'ortho.treatment' | translate }}</th>
          <td mat-cell *matCellDef="let recordData">
            <ng-container *ngIf="recordData.treatmentStatus">
              <stx-treatment-status-indicator
                [addBottomPadding]="true"
                [showLabel]="false"
                [treatmentStatus]="recordData.treatmentStatus"
              ></stx-treatment-status-indicator>

              <a
                *ngIf="recordData.treatmentInScope"
                [routerLink]="['/' + orthoRoute + '/' + orthoRoutes[recordData.interventionType].treatment + '/' + recordData.treatmentId]"
                [queryParams]="{ patientId: recordData.patientId }"
                class="flex-center fs-14"
              >
                <stx-treatment-date
                  class="bold"
                  [direction]="'row'"
                  [createDate]="recordData.treatmentCreated"
                  [submitDate]="recordData.treatmentSubmitted"
                ></stx-treatment-date>
              </a>
              <stx-simple-report-cell
                [inlineInputs]="[recordData.treatmentStartDate, recordData.treatmentEndDate]"
              ></stx-simple-report-cell>
              <stx-simple-report-cell
                *ngIf="recordData.treatmentInScope"
                [inlineInputs]="[
                  recordData.treatmentSurgeonName,
                  recordData.treatmentSurgeonNameLoc | brackets,
                  recordData.treatmentOrthodontistName,
                  recordData.treatmentOrthodontistNameLoc | brackets
                ]"
              ></stx-simple-report-cell>
              <stx-simple-report-cell
                *ngIf="!recordData.treatmentInScope"
                [inlineInputsToTranslate]="['treatment_center.other_st_tc']"
              ></stx-simple-report-cell>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="midAssessment">
          <th mat-header-cell *matHeaderCellDef>{{ 'ortho.midway' | translate }}</th>
          <td mat-cell *matCellDef="let recordData">
            <ng-container *ngIf="recordData.reimbursableMidStatus">
              <stx-treatment-status-indicator
                [addBottomPadding]="true"
                [showLabel]="false"
                [treatmentStatus]="recordData.reimbursableMidStatus"
              ></stx-treatment-status-indicator>
              <a
                *ngIf="recordData.reimbursableMidInScope"
                [routerLink]="[
                  '/' + orthoRoute + '/' + orthoRoutes[recordData.interventionType].midAssessment + '/' + recordData.reimbursableMidId
                ]"
                [queryParams]="{ patientId: recordData.patientId }"
                class="flex-center fs-14"
              >
                <stx-treatment-date
                  class="bold"
                  [direction]="'row'"
                  [createDate]="recordData.reimbursableMidCreated"
                  [submitDate]="recordData.reimbursableMidSubmitted"
                ></stx-treatment-date>
              </a>

              <stx-simple-report-cell [rowInputs]="getMidDataToDisplay(recordData)"> </stx-simple-report-cell>
              <stx-simple-report-cell
                *ngIf="!recordData.reimbursableMidInScope"
                [inlineInputsToTranslate]="['treatment_center.other_st_tc']"
              ></stx-simple-report-cell>
              <div *ngIf="recordData.midCount > 1" class="flex-row-container layout-align-center">
                <mat-icon class="m-r-5">add_circle_outline</mat-icon>
                <div>{{ recordData.midCount - 1 }}</div>
              </div>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="applianceType">
          <th mat-header-cell *matHeaderCellDef>{{ 'reports.orthodontic.ortho_appliance_type' | translate }}</th>
          <td mat-cell *matCellDef="let recordData">
            <stx-simple-report-cell [rowInputsToTranslate]="getOrthoApplianceTypes(recordData)"> </stx-simple-report-cell>
          </td>
        </ng-container>

        <ng-container matColumnDef="other">
          <th mat-header-cell *matHeaderCellDef>{{ 'other' }}</th>
          <td mat-cell *matCellDef="let recordData" class="white-space-normal">
            <stx-simple-report-cell [inlineInputs]="[recordData.otherDevice]"></stx-simple-report-cell>
          </td>
        </ng-container>

        <ng-container matColumnDef="photos">
          <th mat-header-cell *matHeaderCellDef>{{ 'photos' | translate }}</th>
          <td mat-cell *matCellDef="let recordData">
            <stx-image-thumbnail
              thumbnailId="photo"
              imageAlt="{{ 'photos' | translate }}"
              [imageUrl]="recordData.photo"
              [mediaId]="recordData.photoId"
            >
            </stx-image-thumbnail>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="reportColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: reportColumns"></tr>
      </table>
    </div>

    <mat-paginator
      [length]="totalResults"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      [pageIndex]="currentPageIndex"
      (page)="changePage($event)"
    ></mat-paginator>
  </div>
</ng-container>
<div *ngIf="reportTableDataService.dataReady && dataSource?.data.length === 0">
  <stx-empty-table-state></stx-empty-table-state>
</div>
