import { BaseComponent } from '@/src/app/shared/components/base-component/base.component';
import {
  DataTableDataProvider,
  GenericDataTableDataProvider
} from '@/src/app/shared/modules/general-commons/components/data-table/data-table-data-provider';
import { FileUtils } from '@/src/app/utils/file.utils';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SearchFilterService } from '../../../search/services/search-filter.service';
import { PartnerSearchModel } from '../../models/partner-search.model';
import { PartnerService } from '../../partner.service';
import { WsHelperService } from '@shared/services/ws-helper.service';
import { GlobalErrorHandlerService } from '@shared/services/global-error-handler.service';

@Component({
  templateUrl: './partner-search-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SearchFilterService]
})
export class PartnerSearchPageComponent extends BaseComponent implements OnInit {
  private searchFilters: PartnerSearchModel;
  searchResultsDataProvider: DataTableDataProvider<any>;
  constructor(
    private partnerService: PartnerService,
    private searchFilterService: SearchFilterService,
    private wsHelperService: WsHelperService,
    private globalErrorHandlerService: GlobalErrorHandlerService
  ) {
    super();
    this.searchResultsDataProvider = new GenericDataTableDataProvider(
      {
        loadFunction: pageSort => wsHelperService.callWithSpinner(partnerService.getPartners(pageSort, this.searchFilters))
      },
      wsHelperService,
      globalErrorHandlerService
    );
  }
  ngOnInit(): void {
    this.subSink.sink = this.searchFilterService.searchEvent$.subscribe((searchFilters: PartnerSearchModel) => {
      this.searchFilters = searchFilters;
      this.searchResultsDataProvider.reload();
    });

    this.subSink.sink = this.searchFilterService.csvDownloadEvent$.subscribe((searchFilters: PartnerSearchModel) => {
      this.wsHelperService.call(this.partnerService.getPartnersCsvFile(searchFilters)).subscribe(res => {
        FileUtils.downloadFile(res, 'STX-partners.csv');
      });
    });
  }
}
