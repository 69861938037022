<ng-container *ngIf="dataSource?.data.length">
  <mat-paginator
    *ngIf="showPagination"
    [length]="totalResults"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [pageIndex]="currentPageIndex"
    (page)="changePage($event)"
  ></mat-paginator>
  <div class="data-tile-container table-container-common">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z2" aria-describedby="data-tile-container">
      <ng-container matColumnDef="patient">
        <th mat-header-cell *matHeaderCellDef class="visibility-off"></th>
        <td mat-cell *matCellDef="let recordData; let i = index" class="p-0">
          <stx-compact-patient-header [rowNumber]="calcAbsoluteRowIndex(i)" [status]="recordData.assessmentStatus">
            <ng-container slot="prefix">
              <div>{{ orthoTypeToTranslationKeyMap[recordData.interventionType] | translate }} &nbsp;</div>
            </ng-container>
            <ng-container slot="suffix">
              <ng-container *ngIf="recordData.reimbursableMidStatus">
                <stx-value-separator></stx-value-separator>
                <stx-treatment-status-indicator
                  [treatmentStatus]="recordData.reimbursableMidStatus"
                  [defaultIconColor]="true"
                ></stx-treatment-status-indicator>
              </ng-container>
              <ng-container *ngIf="recordData.treatmentStatus">
                <stx-value-separator></stx-value-separator>
                <stx-treatment-status-indicator
                  [treatmentStatus]="recordData.treatmentStatus"
                  [defaultIconColor]="true"
                ></stx-treatment-status-indicator>
              </ng-container>
            </ng-container>
          </stx-compact-patient-header>
          <div class="p-l-24 p-r-24 p-b-10">
            <stx-compact-patient-data *ngIf="!deIdentified" [recordData]="recordData"></stx-compact-patient-data>
            <stx-inline-readonly-field>
              <stx-inline-readonly-field *ngIf="!deIdentified" labelKey="patient.gender" [values]="[recordData.gender, '']">
              </stx-inline-readonly-field>
              {{ 'ortho.service_start_date' | translate }}
              {{ recordData.treatmentStartDate | date : dateFormat }}
              —
              {{ 'ortho.service_end_date' | translate }}
              {{ recordData.treatmentEndDate | date : dateFormat }}
              <stx-value-separator></stx-value-separator>
              {{ 'age' | translate }}
              {{ recordData.treatmentAgeMonth | ageYearMonth }}
              —
              {{ recordData.treatmentAgeMonthEnd | ageYearMonth | if : !!recordData.treatmentEndDate }}
            </stx-inline-readonly-field>
            <stx-inline-readonly-field
              *ngIf="!deIdentified"
              labelKey="practitioner.orthodontist"
              [values]="[recordData.assessmentOrthodontistName | localName : recordData.assessmentOrthodontistNameLoc]"
            >
            </stx-inline-readonly-field>
            <stx-inline-readonly-field [dictionaryValues]="getInterventionsDict(recordData.interventions)"> </stx-inline-readonly-field>
            <ng-container *ngIf="showQaOnlySection">
              <mat-divider></mat-divider>
              <stx-readonly-view-columns>
                <ng-container *stxReadonlyViewColumn>
                  <stx-readonly-view-section-header labelKey="ortho_qa_note.grades"></stx-readonly-view-section-header>
                  <ng-container *ngIf="recordData.qaPreRank && recordData.qaPostRank">
                    <stx-readonly-field
                      >{{ getRankText(recordData.qaPreRank) | translate }} |
                      {{ getRankText(recordData.qaPostRank) | translate }}</stx-readonly-field
                    >
                  </ng-container>
                </ng-container>
                <ng-container *stxReadonlyViewColumn>
                  <stx-readonly-view-section-header labelKey="ortho_qa_note.clinical"></stx-readonly-view-section-header>
                  <ng-container *ngFor="let clinicalNote of recordData.clinicalNotes">
                    <stx-readonly-field>{{ clinicalNote | translate }}</stx-readonly-field>
                  </ng-container>
                  <stx-readonly-field>{{ recordData.qaReviewerNotes }}</stx-readonly-field>
                </ng-container>
                <ng-container *stxReadonlyViewColumn>
                  <stx-readonly-view-section-header labelKey="ortho_qa_note.technical_comments"></stx-readonly-view-section-header>
                  <ng-container *ngFor="let technicalNote of recordData.technicalNotes">
                    <stx-readonly-field>{{ technicalNote | translate }}</stx-readonly-field>
                  </ng-container>
                  <stx-readonly-field>{{ recordData.qaReviewerTechNotes }}</stx-readonly-field>
                </ng-container>
                <ng-container *stxReadonlyViewColumn>
                  <stx-readonly-view-section-header labelKey="ortho_qa_note.internal_comments"></stx-readonly-view-section-header>
                  <ng-container *ngFor="let internalNote of recordData.internalNotes">
                    <stx-readonly-field>{{ internalNote | translate }}</stx-readonly-field>
                  </ng-container>
                  <stx-readonly-field>{{ recordData.qaReviewerStNotes }}</stx-readonly-field>
                </ng-container>
              </stx-readonly-view-columns>
            </ng-container>
            <hr class="form-section-separator" />
            <div class="m-t-15 flex-column-container">
              <ng-container *ngFor="let photo of recordData.assessmentAndTreatmentPhotoPairs; index as index; odd as isOdd; even as isEven">
                <div *ngIf="isEven" class="flex-row-container gap-50 align-left" ngClass.lt-sm="wrap">
                  <div class="m-b-30">
                    <stx-ortho-compact-report-image-pair
                      [showHeaders]="index === 0"
                      [preTreatmentImagesUrl]="photo.preTreatment | ifEmpty : [placeholderImage]"
                      [preTreatmentIds]="[photo.preTreatmentId]"
                      [postTreatmentImagesUrl]="photo.postTreatment | ifEmpty : [placeholderImage]"
                      [postTreatmentIds]="[photo.postTreatmentId]"
                      [postPhotoAnonymized]="photo.postAnonymized"
                      [prePhotoAnonymized]="photo.preAnonymized"
                      [labelPrefix]="photo.labelPrefix"
                      [label]="photo.label"
                    ></stx-ortho-compact-report-image-pair>
                  </div>
                  <div class="m-b-30" *ngIf="!!recordData.assessmentAndTreatmentPhotoPairs[index + 1]">
                    <stx-ortho-compact-report-image-pair
                      [showHeaders]="index + 1 === 1"
                      [preTreatmentImagesUrl]="
                        recordData.assessmentAndTreatmentPhotoPairs[index + 1].preTreatment | ifEmpty : [placeholderImage]
                      "
                      [preTreatmentIds]="[recordData.assessmentAndTreatmentPhotoPairs[index + 1].preTreatmentId]"
                      [postTreatmentImagesUrl]="
                        recordData.assessmentAndTreatmentPhotoPairs[index + 1].postTreatment | ifEmpty : [placeholderImage]
                      "
                      [postTreatmentIds]="[recordData.assessmentAndTreatmentPhotoPairs[index + 1].postTreatmentId]"
                      [labelPrefix]="photo.labelPrefix"
                      [label]="recordData.assessmentAndTreatmentPhotoPairs[index + 1].label"
                      [postPhotoAnonymized]="recordData.assessmentAndTreatmentPhotoPairs[index + 1].postAnonymized"
                      [prePhotoAnonymized]="recordData.assessmentAndTreatmentPhotoPairs[index + 1].preAnonymized"
                    ></stx-ortho-compact-report-image-pair>
                  </div>
                </div>
              </ng-container>
              <div class="fs-16 m-b-25 heading" *ngIf="!!recordData.reimbursableMidPhotos && recordData.reimbursableMidPhotos.length > 0">
                {{ 'ortho.in_progress_photos' | translate }}
              </div>
              <div class="flex-row-container wrap half-full-width">
                <ng-container *ngFor="let photo of recordData.reimbursableMidPhotos">
                  <div class="m-r-50">
                    <stx-image-thumbnail
                      [thumbnailId]="'mid-photo-' + photo.preTreatmentId"
                      imageAlt="{{ photo.fullImageLabel }}"
                      [imageUrl]="photo.preTreatment[0]"
                      [mediaId]="photo.preTreatmentId"
                    >
                      {{ photo.fullImageLabel }}
                    </stx-image-thumbnail>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      *ngIf="showPagination"
      [length]="totalResults"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      [pageIndex]="currentPageIndex"
      (page)="changePage($event)"
    ></mat-paginator>
  </div>
</ng-container>
<div *ngIf="reportTableDataService.dataReady && dataSource?.data.length === 0">
  <stx-empty-table-state></stx-empty-table-state>
</div>
