<div [class.no-data]="isContentHidden" class="data-table">
  <mat-paginator
    #topPaginator
    [class.hidden-top-paginator]="isTopPaginatorHidden"
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChanged(true, $event)"
  ></mat-paginator>
  <div class="data-table-container" [class.no-data]="isEmpty">
    <div class="data-table-wrapper">
      <table mat-table [dataSource]="rows$" matSort (matSortChange)="onSortingChanged()" aria-describedby="data-table-container">
        <ng-content></ng-content>
        <ng-container
          *ngFor="let genericColumn of genericColumns; trackBy: trackByIndex; let colIndex = index"
          [matColumnDef]="genericColumn.name"
        >
          <th
            [ngClass.gt-md]="config?.stickyColumnClass"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [ngStyle]="{ width: getColWidth(colIndex) }"
          >
            {{ genericColumn.text }}
          </th>
          <td [ngClass.gt-md]="config?.stickyColumnClass" *matCellDef="let row" [ngStyle]="{ width: getColWidth(colIndex) }">
            <stx-data-table-generic-column-definition
              [data]="row[genericColumn.name]"
              [inactive]="row[genericColumn.name]?.inactive"
              [isTextWrapped]="config?.colWidthConfig?.length > 0"
            ></stx-data-table-generic-column-definition>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
        <ng-container *ngIf="userDefinedRowDefs.length === 0">
          <tr mat-row *matRowDef="let row; columns: columnNames"></tr>
        </ng-container>
      </table>
    </div>
    <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" (page)="onPageChanged(false, $event)"></mat-paginator>
  </div>
  <stx-empty-table-state *ngIf="isEmpty"></stx-empty-table-state>
</div>
