import { PermissionEnum } from '@/src/app/core/permissions/permission.enum';
import { EmailHelpModalComponent } from '@/src/app/features/authentication/components/help-modal/help-modal.component';
import { ResetPasswordModalComponent } from '@/src/app/features/authentication/components/reset-password-modal/reset-password-modal.component';
import { LoginCredentials } from '@/src/app/features/authentication/models/login-credentials.model';
import { AsyncButtonClickAction } from '@/src/app/utils/button.utils';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TempLangService } from '@shared/services/localisation/temp-lang.service';
import { AngularEmbeddedValidators } from '@shared/validation/angular-embedded.validators';
import { getLanguageByShortcut } from '@utils/language.utils';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shared/components/spinner/service/spinner.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PasswordExpiryService } from '@src/app/features/authentication/services/password-expiry.service';
import { CurrentUserService } from '@src/app/features/authentication/services/current-user.service';
import { AuthService } from '@src/app/features/authentication/services/auth.service';

@Component({
  selector: 'stx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private static readonly credentialsExpiredErrorKey = 'credentials_expired';
  loginForm: UntypedFormGroup;
  hidePassword = true;
  showAuthError: boolean;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private currentUserService: CurrentUserService,
    private dialog: MatDialog,
    private router: Router,
    private cd: ChangeDetectorRef,
    private spinnerService: SpinnerService,
    private translateService: TranslateService,
    private angularEmbeddedValidators: AngularEmbeddedValidators,
    private readonly tempLangService: TempLangService,
    private passwordExpiryService: PasswordExpiryService
  ) {}

  ngOnInit() {
    this.initializeLoginForm();
    this.currentUserService.getUserSubject().subscribe({
      next: user => {
        if (user) {
          this.router.navigateByUrl('/');
        } else {
          this.showAuthError = false;
        }
      }
    });
  }

  private initializeLoginForm(): void {
    this.loginForm = this.formBuilder.group({
      email: [null, (control: AbstractControl) => this.angularEmbeddedValidators.required(control)],
      password: [null, (control: AbstractControl) => this.angularEmbeddedValidators.required(control)]
    });
  }

  login: AsyncButtonClickAction = () => {
    if (!this.loginForm.valid) {
      return of(null);
    }
    this.tempLangService.clearTempLanguage();
    this.spinnerService.addTask();
    const credentials: LoginCredentials = {
      email: this.loginForm.controls.email.value,
      password: this.loginForm.controls.password.value
    };
    return this.authService.loginWithDefaultCredentials(credentials, this.onLoginError.bind(this)).pipe(
      tap({
        next: () => {
          this.authService.getUserAndManageTempLang().subscribe({
            next: user => {
              this.currentUserService.setCurrentUser(user);
              this.translateService.use(getLanguageByShortcut(user.lang).name);
              this.spinnerService.removeTask();
              if (user.profiles.length > 1) {
                this.router.navigateByUrl('/select-profile');
              } else {
                const roleNames = user.permissions.map(p => p.name);
                if (roleNames.includes(PermissionEnum.VIEW_DASHBOARD_MAIN)) {
                  this.router.navigateByUrl('/');
                } else if (roleNames.includes(PermissionEnum.VIEW_DASHBOARD_REVIEW_SURGICAL)) {
                  this.router.navigateByUrl('/qa-surgical');
                }
              }
            }
          });
        },
        error: response => {
          if (this.isUserCredentialsExpired(response)) {
            this.router.navigate(['reset-password'], { queryParams: { key: response.error.resetKey } });
            this.passwordExpiryService.setShowExpiredPasswordMessage(true);
          }
          this.showAuthError = true;
          this.spinnerService.removeTask();
          this.cd.detectChanges();
        }
      })
    );
  };

  isUserCredentialsExpired(response: HttpErrorResponse): boolean {
    return response.error.errorKey === LoginComponent.credentialsExpiredErrorKey;
  }

  openResetPasswordModal(): void {
    this.dialog.open(ResetPasswordModalComponent, { autoFocus: false });
  }

  openHelpModal(): void {
    this.dialog.open(EmailHelpModalComponent, { autoFocus: false });
  }

  onLoginError(): void {
    this.showAuthError = true;
    this.spinnerService.removeTask();
    this.cd.detectChanges();
  }
}
