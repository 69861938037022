import { AllowedTreatmentsConfig } from '@/src/app/features/patient/models/allowed-treatments-config.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PatientActionType } from '@shared/components/patient-details-actions/patient-action-type.enum';
import { TreatmentType } from '@shared/components/treatment/treatment.enum';
import { TreatmentCenterDictionary } from '@shared/models/treatment-center.model';
import { BaseHttpService } from '@shared/services/http/base-http.service';
import { PatientTreatmentHistory } from '@src/app/features/patient/models/patient-treatment-history.model';
import { patientsRoute } from '@utils/routing.utils';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DictionaryTranslation, MatchedPatient, Patient, PatientListItem, ReleaseForms } from './models/patient.model';

@Injectable({
  providedIn: 'root'
})
export class PatientService extends BaseHttpService {
  url = 'static-dictionaries';
  bufferSize = 1;

  private dictionary$: Observable<Map<string, DictionaryTranslation[]>>;

  private updatePatientsEvent = new Subject<PatientActionType>();

  constructor(httpClient: HttpClient) {
    super(httpClient, { baseUrl: patientsRoute });
  }

  public getStaticDictionaries(): Observable<Map<string, DictionaryTranslation[]>> {
    if (!this.dictionary$) {
      this.dictionary$ = this.httpClient
        .get<Map<string, DictionaryTranslation[]>>(`${this.baseUrl}/${this.url}`)
        .pipe(shareReplay(this.bufferSize))
        .pipe(map(res => new Map(Object.entries(res))));
    }
    return this.dictionary$;
  }

  public draftPatient(patientDraft: Patient, confirmPreconditions: boolean): Observable<Patient> {
    const headers = this.getPreconditionsHeader(confirmPreconditions, new HttpHeaders());
    return this.httpClient.post<Patient>(`${this.baseUrl}/draft`, patientDraft, { headers: headers });
  }

  public submitPatient(patient: Patient, confirmPreconditions: boolean): Observable<Patient> {
    const headers = this.getPreconditionsHeader(confirmPreconditions, new HttpHeaders());
    return this.httpClient.post<Patient>(`${this.baseUrl}/submit`, patient, { headers: headers });
  }

  attachReleaseForm(patientId: number, data: ReleaseForms): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/${patientId}/release-form`, data);
  }

  getReleaseFormTranslations(langShortcut: string) {
    const translationRoute = '/release-form/translations';
    const params = new HttpParams().set('lang', langShortcut);
    return this.httpClient.get<{ first: string; second: string }[]>(`${this.baseUrl}${translationRoute}`, { params });
  }

  public getPatientById(id: number): Observable<Patient> {
    return this.httpClient.get<Patient>(`${this.baseUrl}/${id}`);
  }

  public unlockPatientById(id: number): Observable<void> {
    return this.httpClient.patch<void>(`${this.baseUrl}/unlock/${id}`, null);
  }

  public deletePatientById(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${id}`);
  }

  public getPatientTreatmentCenters(patientId: number, treatmentType: TreatmentType): Observable<TreatmentCenterDictionary[]> {
    const params = new HttpParams().set('treatmentType', treatmentType);
    return this.httpClient.get<TreatmentCenterDictionary[]>(`${this.baseUrl}/${patientId}/treatment-centers`, { params });
  }

  matchPatients(matchedPatient: MatchedPatient): Observable<void> {
    return this.httpClient.post<void>(`${this.baseUrl}/match`, matchedPatient);
  }

  getPotentialMatches(mainPatientId: number): Observable<PatientListItem[]> {
    return this.httpClient.get<PatientListItem[]>(`${this.baseUrl}/potential-matches/${mainPatientId}`);
  }

  emitUpdatePatients(patientActionType: PatientActionType): void {
    this.updatePatientsEvent.next(patientActionType);
  }

  updatePatientsEventListener(): Observable<PatientActionType> {
    return this.updatePatientsEvent.asObservable();
  }

  getAllowedTreatmentsToPatientIds(patientId: number): Observable<AllowedTreatmentsConfig> {
    return this.httpClient.get<AllowedTreatmentsConfig>(`${this.baseUrl}/${patientId}/new-treatments`);
  }

  getPatientTreatmentHistory(patientId: number): Observable<PatientTreatmentHistory> {
    return this.httpClient.get<PatientTreatmentHistory>(`${this.baseUrl}/${patientId}/treatments-history`);
  }

  isPatientEligibleForVpd(patientId: number, operationDate: string): Observable<boolean> {
    const params = new HttpParams().set('operationDate', operationDate);
    return this.httpClient.get<boolean>(`${this.baseUrl}/${patientId}/vpd-eligible`, { params });
  }

  private getPreconditionsHeader(confirmPreconditions: boolean, headers: HttpHeaders) {
    if (confirmPreconditions) {
      headers = headers.set('confirm-request-changes', 'true');
    }
    return headers;
  }
}
