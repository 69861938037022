import { GeneralCommonsModule } from '@/src/app/shared/modules/general-commons/general-commons.module';
import { Mime } from '@/src/app/utils/file.utils';
import { state, style, transition, trigger, useAnimation } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { slideDown, slideUp } from '@core/animations';
import { TIMINGS } from '@core/animations/constant.animations';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { NoticeComponentModule } from '@shared/components/notice/notice.component';
import { ReportRequestService } from '@src/app/features/reports/components/report-filters/services/report-request.service';
import { filter, finalize } from 'rxjs/operators';
import { ReportConfig } from '../../components/report-filters/models/report-filters.model';
import { ReportFiltersModule } from '../../components/report-filters/report-filters.component';
import { ReportService } from '../../components/report-filters/services/report.service';
import { ReportHeaderComponentModule } from '../../components/report-header/report-header.component';
import { ReportRegions } from '@src/app/features/reports/models/report-regions.model';
import { checkRegion } from '@src/app/features/reports/components/reports.utils';
import { WsHelperService } from '@shared/services/ws-helper.service';

const maxYearsDifferenceBetweenDates = 2;

@Component({
  selector: 'stx-month-end-report',
  templateUrl: './month-end-report.component.html',
  animations: [
    trigger('successNotice', [
      state('visible', style({ display: '*' })),
      state(
        'hidden',
        style({
          height: '0',
          overflow: 'hidden'
        })
      ),
      transition('hidden => visible', [
        useAnimation(slideDown, {
          params: {
            timing: TIMINGS.FAST
          }
        })
      ]),
      transition('visible => hidden', [
        useAnimation(slideUp, {
          params: {
            timing: TIMINGS.DEFAULT
          }
        })
      ])
    ])
  ]
})
export class MonthEndReportComponent extends BaseComponent implements OnInit, AfterViewInit {
  private static readonly baseEndpoint = '/month-end/';
  private endpoint: string;
  private treatmentType: string;
  reportConfig: ReportConfig = {
    type: 'MONTH_END',
    downloadOptions: {},
    filters: {
      date: true,
      geography: true,
      organizationsTree: true
    },
    additionalValidators: {
      maxDatesDifferenceInYears: maxYearsDifferenceBetweenDates
    }
  };

  successNoticeVisible = false;
  title: string;

  constructor(
    public reportService: ReportService,
    private activatedRoute: ActivatedRoute,
    private reportRequestService: ReportRequestService,
    private translateService: TranslateService,
    private readonly wsHelperService: WsHelperService
  ) {
    super();
  }

  private get region(): ReportRegions {
    return checkRegion(this.subSink, this.activatedRoute, this.reportService, true);
  }

  private getTreatmentType(): void {
    this.subSink.sink = this.activatedRoute.data.subscribe(data => (this.treatmentType = data.treatmentType.toLowerCase()));
  }

  ngOnInit(): void {
    this.getTreatmentType();
    this.enableSuccessNoticeAutoHide();
    this.reportService.showFilters();
    this.endpoint = MonthEndReportComponent.baseEndpoint + this.treatmentType;
  }

  ngAfterViewInit() {
    this.setTitle();
  }

  downloadReport() {
    this.wsHelperService
      .callWithSpinner(
        this.reportRequestService.getBinary({
          endpoint: this.endpoint,
          filename: this.getFilename(),
          mime: Mime.ZIP,
          filtersModel: this.reportService.model
        })
      )
      .pipe(
        finalize(() => {
          this.reportService.hideFilters();
        })
      )
      .subscribe({
        next: () => {
          this.reportService.hideFilters();
          this.successNoticeVisible = true;
        }
      });
  }

  private enableSuccessNoticeAutoHide() {
    this.subSink.sink = this.reportService.filtersVisible$.pipe(filter(isVisible => isVisible)).subscribe(() => {
      this.successNoticeVisible = false;
    });
  }

  private setTitle() {
    if (!this.region) {
      this.title = 'reports.month_end';
    } else if (this.region === ReportRegions.CHINA) {
      this.title = 'reports.month_end.china';
    } else if (this.region === ReportRegions.INDIA) {
      this.title = 'reports.month_end.india';
    } else if (this.region === ReportRegions.PHILIPPINES) {
      this.title = 'reports.month_end.philippines';
    }
  }

  private getFilename(): string {
    const regionTranslation = this.region ? this.title : 'report.month_end.filename_all_regions';
    const basename = this.translateService.instant('reports.month_end');
    const region = this.translateService.instant(regionTranslation);

    return `${basename}_${region}.zip`;
  }
}

@NgModule({
  imports: [CommonModule, NoticeComponentModule, ReportFiltersModule, ReportHeaderComponentModule, TranslateModule, GeneralCommonsModule],
  declarations: [MonthEndReportComponent],
  exports: [MonthEndReportComponent]
})
export class MonthEndReportComponentModule {}
