import { MediaUrl } from '@/src/app/features/media/models/media-url.model';
import { WsHelperService } from '@/src/app/shared/services/ws-helper.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'src/app/shared/services/http/base-http.service';

@Injectable({
  providedIn: 'root'
})
export class MediaService extends BaseHttpService {
  constructor(httpClient: HttpClient, private wsHelper: WsHelperService) {
    super(httpClient, { baseUrl: 'media' });
  }

  getStaticFile(fileName: string, accept: string): Observable<Blob> {
    const params = new HttpParams().set('filePath', fileName);
    const headers: HttpHeaders = new HttpHeaders({ Accept: accept });
    return this.httpClient.get(`${this.baseUrl}/static`, { params, responseType: 'blob', headers });
  }

  getHighResMediaUrl(id: number): Observable<MediaUrl> {
    return this.wsHelper.callWithSpinner(this.httpClient.get<MediaUrl>(`${this.baseUrl}/url/${id}/high-res`));
  }
}
