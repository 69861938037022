import { OrthognathicTreatment } from '@/src/app/features/orthognathic/orthognathic-treatment.model';
import { OrthognathicService } from '@/src/app/features/orthognathic/orthognathic.service';
import { BaseTreatmentFormComponent } from '@/src/app/shared/components/base-treatment-form/base-treatment-form.component';
import { FormType } from '@/src/app/shared/enums/form-type.enum';
import { WsHelperService } from '@/src/app/shared/services/ws-helper.service';
import { StxValidators } from '@/src/app/shared/validation/validators';
import { getNextDay, getToday } from '@/src/app/utils/date.utils';
import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGuardService } from '@shared/services/form-guard.service';
import { SnackBarService } from '@shared/services/snack-bar.service';
import { Observable } from 'rxjs';
import { PatientService } from 'src/app/features/patient/patient.service';
import { SpinnerService } from 'src/app/shared/components/spinner/service/spinner.service';
import { TreatmentType } from 'src/app/shared/components/treatment/treatment.enum';
import { ParentOrderName } from 'src/app/shared/enums/parent-order-name.enum';
import { GlobalErrorHandlerService } from 'src/app/shared/services/global-error-handler.service';
import { Mime } from 'src/app/utils/file.utils';
import { FormMediaUtils } from 'src/app/utils/form-media.utils';
import { FormMode } from 'src/app/utils/form.utils';
import { getImageUrl } from 'src/app/utils/media.utils';
import { orthognathicRoute } from 'src/app/utils/routing.utils';
import { TreatmentId } from '@src/app/features/surgical/models/base-treatment.model';

@Component({
  selector: 'stx-orthognathic-treatment',
  templateUrl: './orthognathic-treatment.component.html'
})
export class OrthognathicTreatmentComponent extends BaseTreatmentFormComponent<OrthognathicTreatment> implements OnInit {
  acceptedTypes = [Mime.GIF, Mime.JPEG, Mime.JPG, Mime.PNG, Mime.PDF];
  minDate = getToday();
  fileUrl: string;

  @Input() orthognathicTreatment: OrthognathicTreatment;

  constructor(
    private formBuilder: UntypedFormBuilder,
    spinnerService: SpinnerService,
    patientService: PatientService,
    globalErrorHandlerService: GlobalErrorHandlerService,
    private orthognathicService: OrthognathicService,
    router: Router,
    activatedRoute: ActivatedRoute,
    private formGuardService: FormGuardService,
    snackBarService: SnackBarService,
    changeDetector: ChangeDetectorRef,
    elementRef: ElementRef,
    zone: NgZone,
    wsHelper: WsHelperService
  ) {
    super(
      elementRef,
      zone,
      snackBarService,
      spinnerService,
      router,
      activatedRoute,
      changeDetector,
      globalErrorHandlerService,
      wsHelper,
      patientService
    );
    this.formType = FormType.ORTHOGNATHIC_TREATMENT;
    this.treatmentType = TreatmentType.ORTHOGNATHIC;
  }

  ngOnInit() {
    this.configureForm(this.initOrthognathicForm());
    this.getOrthognathicTreatment();
    if (!this.isReadonlyView) {
      this.getPatientTreatmentCenters();
    }
  }

  private initOrthognathicForm(): UntypedFormGroup {
    if (this.patient) {
      this.minDate = getNextDay(this.patient.dateOfBirth);
    }
    return this.formBuilder.group({
      orthognOperationDate: [null, StxValidators.required],
      treatmentCenterId: [null, StxValidators.required],
      [ParentOrderName.ORTHOGNATHIC]: [null, StxValidators.required]
    });
  }

  protected getTreatmentDataToSave(): OrthognathicTreatment {
    return {
      patientId: this.patient.id,
      ...this.treatmentFormGroup.value,
      newFiles: FormMediaUtils.extractNewMediaFromFormGroup([this.treatmentFormGroup], [ParentOrderName.ORTHOGNATHIC])
    };
  }

  private getOrthognathicTreatment() {
    if (this.formMode !== FormMode.NEW) {
      if (!this.orthognathicTreatment) {
        this.subSink.sink = this.activatedRoute.params.subscribe(params => {
          this.wsHelper
            .callWithSpinner(this.orthognathicService.getOrthognathicTreatment(params.id), { redirectOn404StatusCode: true })
            .subscribe(orthognathicTreatment => {
              this.formGuardService.redirectTreatmentFormToCorrectModeIfRequired(
                orthognathicTreatment,
                this.formMode,
                this.activatedRoute.snapshot
              );
              this.setTreatmentData(orthognathicTreatment);
            });
        });
      } else {
        this.setTreatmentData(this.orthognathicTreatment);
      }
    }
  }

  protected callDelete(id: number): Observable<void> {
    return this.orthognathicService.deleteOrthognathic(id);
  }

  protected callSave(data: OrthognathicTreatment): Observable<OrthognathicTreatment> {
    throw new Error('Unexpected action');
  }

  protected callSubmit(data: OrthognathicTreatment): Observable<OrthognathicTreatment> {
    return this.orthognathicService.submitOrthognathicTreatment(data);
  }

  protected callUnlock(id: number): Observable<void> {
    return this.orthognathicService.unlockOrthognathic(id);
  }

  protected getEditRoute(treatmentId: number): string {
    throw new Error('Unexpected action');
  }

  protected getPatientId(): number {
    return this.patient.id;
  }

  protected getTreatmentId(): TreatmentId {
    return this.orthognathicTreatment.id;
  }

  protected getViewRoute(treatmentId: number): string {
    return `${orthognathicRoute}/${treatmentId}`;
  }

  protected setTreatmentData(data: OrthognathicTreatment): void {
    this.orthognathicTreatment = data;
    this.treatmentFormGroup.patchValue(data);
    this.fileUrl = getImageUrl(data?.mediaResources, ParentOrderName.ORTHOGNATHIC);
  }

  get shouldDisplayRemoveFileIcon(): boolean {
    return this.formMode === FormMode.NEW;
  }
}
